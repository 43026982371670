<template>
  <login-layout>
    <div>
      <header class="login-pf-header">
        <h1>{{ $t('Sito in manutenzione') }}</h1>
      </header>

      {{ $t('I nostri servizi online sono attualmente in manutenzione.') }}<br>
      {{ $t('Il sito tornerà in funzione al 100% tra pochi istanti, prova a ricaricare la pagina ora.') }}<br>
    </div>

    <pf-button variant="primary" large block :disabled="reloading" @click="reload">
      {{ $t('Ricarica') }}
      <div v-if="reloading" class="spinner spinner-xs" />
    </pf-button>
  </login-layout>
</template>

<style scoped>
.separator::before {
  display: block;
  content: '';
  width: 50%;
  margin: 20px auto;
  border-top: 1px solid #bbb;
}
</style>

<script lang="ts">
import { sleep } from '@common/utils';
import LoginLayout from '../components/login-layout.vue';
import { useLoginStore } from '@/store/login';
import { useAppStore } from '@/store/app';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MaintenancePage',

  components: {
    LoginLayout,
  },

  data() {
    return {
      reloading: false,
      timer: undefined as number | undefined,
    };
  },

  mounted() {
    this.timer = window.setInterval(this.reload, 20000);
  },

  beforeUnmount() {
    clearInterval(this.timer);
  },

  methods: {
    async reload() {
      if (this.reloading) {
        return;
      }

      const app = useAppStore();
      const login = useLoginStore();

      this.reloading = true;

      try {
        await login.refresh();
        if (!app.maintenance) {
          location.reload();
        } else {
          await sleep(5000);
        }
      } catch (e) {
        await sleep(5000);
      } finally {
        this.reloading = false;
      }
    },
  },
});
</script>
