<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :title="$t('Location')">
    <div class="question-wrapper">
      <h3>{{ $t('Location principale') }}</h3>
      <wizard-question-location v-model="wizard.sceltaLocationDeploy" name="deployLocation" :opzioni-location="wizard.deployLocations" required />
    </div>

    <div v-if="wizard.askLocationBackup" class="question-wrapper">
      <h3>{{ $t('Location di backup') }}</h3>
      <wizard-question-location v-model="wizard.sceltaLocationBackup" name="backupLocation" :opzioni-location="wizard.backupLocations" required />
    </div>
  </wizard-form>
</template>

<script lang="ts" setup>
import { useOUIAProps, type OUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';

import WizardQuestionLocation from '../questions/wizard-question-location.vue';
import WizardForm from '../wizard-form.vue';

defineOptions({
  name: 'WizardStepLocation',
});

const props = defineProps<OUIAProps>();
const ouiaProps = useOUIAProps(props);

const { wizard, hidden, isEnabled } = useWizardStep({
  isEnabled: wizard => wizard.askLocation,
});

defineExpose({
  isEnabled,
});
</script>
