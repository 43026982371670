<template>
  <login-layout>
    <header class="login-pf-header">
      <h1>{{ $t('Scegli a quale pannello di controllo vuoi accedere.') }}</h1>
    </header>

    <form v-if="asyncComputed.aziende.state === 'success'" @submit.prevent="submit">
      <div class="form-group">
        <select v-model="id_azienda" class="form-control" required>
          <option v-for="(az, index) in aziende" :key="index" :value="index">{{ az.azienda }}</option>
        </select>
      </div>

      <pf-button type="submit" variant="primary" large block :disabled="sending || !id_azienda">
        {{ $t('Accedi') }}
        <div v-if="sending" class="spinner spinner-xs" />
      </pf-button>

      <a class="btn btn-default btn-block btn-lg" @click="app.logout()">{{ $t('Logout') }}</a>
    </form>
    <div v-else class="spinner spinner-lg blank-slate-pf-icon" />
  </login-layout>
</template>

<script lang="ts">
import { http } from '../../http';
import { setupAsyncComputed } from '@common/asyncComputed';
import { loginDestination } from '../../utils';
import LoginLayout from '../../components/login-layout.vue';
import { defineComponent } from 'vue';
import { useLoginStore } from '@/store/login';
import { useAppStore } from '@/store/app';
import { CompanyAccessResource, type CompanyAccess } from '@/resources';

export default defineComponent({
  name: 'LoginAziendaPage',

  components: {
    LoginLayout,
  },

  setup() {
    const app = useAppStore();

    return {
      app,
      ...setupAsyncComputed({
        aziende: {
          get: async() => await new CompanyAccessResource().get(),
          default: {} as Record<number, CompanyAccess>,
        },
      }),
    };
  },

  data() {
    return {
      sending: false,
      id_azienda: null as string | null,
    };
  },

  watch: {
    aziende() {
      const ids = Object.keys(this.aziende);
      this.id_azienda = ids.length ? ids[0] : null;
    },
  },

  methods: {
    async submit() {
      if (!this.id_azienda) {
        return;
      }

      this.sending = true;

      try {
        const data = new FormData();
        data.append('id_azienda', this.id_azienda);

        const result = (await http.post('/api/auth/companylogin', data)).data.result;

        if (result == 'ok') {
          const login = useLoginStore();
          await login.refresh();
          this.$router.push(loginDestination(this.$route.query));
        }
      } finally {
        this.sending = false;
      }
    },
  },
});
</script>
