<template>
  <div v-if="introductionState.state !== 'success'" class="blank-slate-pf">
    <div class="spinner spinner-lg blank-slate-pf-icon" />
    <h3 class="blank-slate-pf-main-action">
      {{ $t('Stiamo caricando le domande e le tue risposte precedenti') }}
    </h3>
  </div>

  <form v-else @submit.prevent="submit">
    <template v-if="variant === 'survey'">
      <p>{{ _t('Benvenuti nel nostro questionario annuale obbligatorio per i partner!') }}</p>
      <p>{{ _t('Questo strumento è pensato per farci comprendere al meglio le vostre necessità e aspettative, nonché per assicurarci che i dati che ci fornite siano sempre aggiornati.') }}</p>
      <p>{{ _t('Raccogliendo informazioni dettagliate sulle vostre competenze e caratteristiche, possiamo indirizzare in modo più accurato i lavori verso i partner più adatti, migliorando l\'efficienza e la soddisfazione di tutti.') }}</p>
      <p>{{ _t('Vi chiederemo di dedicare un momento per compilare questo questionario una volta all\'anno, garantendo così l\'attualità e la precisione delle informazioni condivise. La vostra partecipazione è essenziale per rafforzare la nostra collaborazione e guidarci verso un futuro di successo congiunto.') }}</p>
      <p>{{ _t('Grazie per il vostro impegno e la vostra collaborazione.') }}</p>
    </template>

    <template v-if="variant === 'introduction'">
      <div class="form-group">
        <label for="presentazione">
          {{ _t("Presentazione") }}
          <form-help-tooltip :text="$t('Descrivi brevemente la tua aziende e i prodotti e servizi che fornisce')" />
        </label>
        <textarea
          v-model="introduction.presentazione"
          :required="required"
          name="presentazione"
          class="form-control"
          rows="5"
        />
      </div>

      <div class="form-group">
        <label for="logo">
          {{ _t("Logo") }}
          <form-help-tooltip :text="$t('Verrà mostrato nella pagina di registrazione quando gli utenti utilizzeranno il tuo link di affiliazione')" />
        </label>
        <img v-if="introduction.has_logo" :src="`/rest/company_logo/${login.id_azienda}?size=64`"/>

        <pf-button v-if="introduction.has_logo" variant="link" @click="deleteLogo">
          <xmark-icon />{{ $t('Elimina logo') }}
        </pf-button>

        <input
          type="file"
          ref="inputLogo"
          name="logo"
          @change="getLogoAttachment"
          style="margin-top:10px"
        >
      </div>

      <div class="form-group">
        <label for="brochure">
          {{ _t("Brochure") }}
        </label>
        <a v-if="introduction.has_brochure" :href="`/rest/company_brochure/${login.id_azienda}`">
          <file-arrow-down-icon />{{ $t('Brochure') }}
        </a>

        <pf-button v-if="introduction.has_brochure" variant="link" @click="deleteBrochure">
          <xmark-icon />{{ $t('Elimina brochure') }}
        </pf-button>

        <input
          type="file"
          ref="inputBrochure"
          name="brochure"
          @change="getBrochureAttachment"
          style="margin-top:10px"
        >
      </div>
    </template>

    <div v-for="(param, question) of questions" :key="question" class="form-group">
      <label for="question">{{ param.name }}<template v-if="param.required"> *</template></label>
      <select
        id="question"
        v-model="introduction[question]"
        name="question"
        class="form-control"
        :required="param.required"
        :multiple="param.multiple"
      >
        <option v-for="option in param.options" :key="option">
          {{ option }}
        </option>
      </select>
    </div>

    <div v-if="variant === 'introduction'" class="form-group">
      <label>
        {{ _t("Vuoi ricevere segnalazioni relative ad opportunità commerciali che arrivano dai nostri clienti?") }}
        <form-help-tooltip :text="$t('Ti segnalaremo ogni richiesta da parte di nostri clienti relativa a progetti nel tuo settore e nella tua zona.')" />
      </label>
      <toggle
        v-model="introduction.receive_opportunity"
        name="receive_opportunity"
        :on-value="true"
        :off-value="false"
      />
    </div>

    <template v-if="variant === 'survey'">
      <div class="form-group">
        <label>{{ _t("Cosa ti piace dell'area clienti?") }} *</label>
        <textarea
          v-model="introduction.admin_pros"
          name="admin_pros"
          class="form-control"
          :required="required"
        />
      </div>

      <div class="form-group">
        <label>{{ _t("Cosa non ti piace dell'area clienti?") }} *</label>
        <textarea
          v-model="introduction.admin_cons"
          name="admin_cons"
          class="form-control"
          :required="required"
        />
      </div>

      <div class="form-group">
        <label>{{ _t("Quali nuove funzionalità vorresti vedere implementate in quest'area clienti?") }} *</label>
        <textarea
          v-model="introduction.admin_feature_request"
          name="admin_feature_request"
          class="form-control"
          :required="required"
        />
      </div>
    </template>

    <pf-button type="submit" :disabled="sending">{{ _t('Invia') }}</pf-button>
  </form>
</template>

<style scoped>
label {
  display: blocK;
}
.form-group{
  max-width: 500px;
}
textarea {
  width: 100%;
  height: 100px;
}
</style>

<script lang="ts" setup>
import { ref, type Ref, computed } from 'vue';
import { $t as _t } from '@/i18n';
import { CRM_ANNIATTIVITA, CRM_ASSOCIAZIONI, CRM_CATEGORIAPROSPECT, CRM_CLIENTI, CRM_DIMENSIONECLIENTI, CRM_DIPENDENTI, CRM_FATTURATO, CRM_PREFERENZAFATTURAZIONE } from '../common/consts';
import { asyncComputed } from '@common/asyncComputed';
import { CompanyBrochureResource, CompanyLogoResource, IntroductionResource } from '@/resources';
import { useLoginStore } from '../store/login';
import Toggle from '../components/toggle.vue';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import FileArrowDownIcon from '@vue-patternfly/icons/file-arrow-down-icon';
import XmarkIcon from '@vue-patternfly/icons/xmark-icon';

const login = useLoginStore();
const props = withDefaults(defineProps<{
  variant?: 'survey' | 'introduction';
}>(), {
  variant: 'introduction',
});

const emit = defineEmits<{
  (e: 'submit'): void
}>();

const inputLogo: Ref<HTMLInputElement | undefined> = ref();
const inputBrochure: Ref<HTMLInputElement | undefined> = ref();
const sending = ref();

const {result: introduction, state: introductionState} = asyncComputed(async() => {
  return await new IntroductionResource().get(1);
});

const required = computed(() => props.variant === 'survey');
const questions = {
  'categoria_prospect': {
    name: _t('Categoria'),
    options: CRM_CATEGORIAPROSPECT,
    required: required.value,
    multiple: false,
  },
  'dipendenti': {
    name: _t('Numero dipendenti'),
    options: CRM_DIPENDENTI,
    required: required.value,
    multiple: false,
  },
  'anni_attivita': {
    name: _t('Anni di attività'),
    options: CRM_ANNIATTIVITA,
    required: required.value,
    multiple: false,
  },
  'fatturato': {
    name: _t('Fatturato attuale'),
    options: CRM_FATTURATO,
    required: required.value,
    multiple: false,
  },
  'clienti': {
    name: _t('Clienti attuali'),
    options: CRM_CLIENTI,
    required: required.value,
    multiple: false,
  },
  'dimensione_clienti': {
    name: _t('Clienti preferiti'),
    options: CRM_DIMENSIONECLIENTI,
    required: required.value,
    multiple: true,
  },
  'associazioni': {
    name: _t('Assiociazioni di cui fate parte'),
    options: CRM_ASSOCIAZIONI,
    required: false,
    multiple: true,
  },
  'preferenza_fatturazione': {
    name: _t('Preferenza di fatturazione'),
    options: CRM_PREFERENZAFATTURAZIONE,
    required: required.value,
    multiple: false,
  },
};

async function submit() {
  if (sending.value) {
    return;
  }

  sending.value = true;

  try {
    await new IntroductionResource().save(introduction.value);
    emit('submit');
  } finally {
    sending.value = false;
  }

  introductionState.update();
};

function getLogoAttachment() {
  if (!inputLogo?.value?.files) {
    return;
  }
  introduction.value.logo = inputLogo.value.files[0];
};

async function deleteLogo() {
  if (!confirm('Vuoi procedere con la cancellazione del logo?')) {
    return
  }
  new CompanyLogoResource().delete(1);
  introductionState.update();
}

function getBrochureAttachment() {
  if (!inputBrochure?.value?.files) {
    return;
  }
  introduction.value.brochure = inputBrochure.value.files[0];
};

async function deleteBrochure() {
  if (!confirm('Vuoi procedere con la cancellazione del brochure?')) {
    return
  }
  new CompanyBrochureResource().delete(1);
  introductionState.update();
}
</script>
