<template>
  <div class="heading row">
    <h2 class="col-sx-12 col-md-4">{{ $t('Storico Movimenti') }}</h2>

    <template v-if="movements.length > 0">
      <div class="col-sx-12 col-md-8">
        <x-input
          type="search"
          v-model="filter"
          :placeholder="$t('Filtra')"
        />
      </div>
    </template>
  </div>
  <template v-if="movements.length > 0">
    <table v-if="filtered.length > 0" class="table dataTable table-bordered">
      <thead>
        <tr>
          <th>{{ $t('Descrizione') }}</th>
          <th>
            <sort-link v-model="sort" v-model:reverse="reverseSort" field="amount">
              {{ $t('Valore') }}
            </sort-link>
          </th>
          <th>
            <sort-link v-model="sort" v-model:reverse="reverseSort" field="data">
              {{ $t('Data') }}
            </sort-link>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="provvigione in paged"
          :key="provvigione.id"
          :class="{ success: provvigione.amount > 0, danger: provvigione.amount < 0 }"
        >
          <td>{{ provvigione.dettagli }}</td>
          <td class="value-cell">{{ $currency(provvigione.amount, currency) }}</td>
          <td>{{ $date(provvigione.data) }} {{ expiringLabel(provvigione.data) }}</td>
        </tr>
      </tbody>
    </table>
    <div v-else class="blank-slate-pf">
      {{ $t('Nessun movimento trovato con il filtro selezionato.') }}
    </div>
    <pf-paginate-control
      type="table"
      :page="pageNum"
      :total-items="filtered.length"
      :items-per-page="itemsPerPage"
      :items-per-page-options="[]"
      @change="pageNum = Number($event)"
    />
  </template>
  <div v-else class="blank-slate-pf">
    {{ $t('Nessun movimento.') }}
  </div>
</template>

<style scoped lang="scss">
tfoot th {
  padding-left: 0;
  padding-right: 0;
  border-top: 2px solid #d1d1d1;
}

.heading{
  margin-top: 40px;
  margin-bottom: 20px;

  h2 {
    margin-top: 0;
  }
}

.value-cell {
  text-align: right;
}
</style>

<script lang="ts" setup>
import { type Ref, ref, computed, watch } from 'vue';
import { by } from '@common/utils';
import { any } from '@common/utils';
import { $t as _t } from '@/i18n';
import SortLink from '../components/sort-link.vue';
import type { AccountMovement } from '@/resources';
import { parseDateTime } from "@common/date";
import { DateTime } from 'luxon';
import XInput from './x-input.vue';

const props = withDefaults(defineProps<{
  movements: AccountMovement[],
  currency: 'CHF' | 'EUR',
  itemsPerPage?: number,
  expiringMonths?: number|null
}>(), {
  itemsPerPage: 30,
  expiringMonths: 15,
});

const pageNum = ref(1);
const filter = ref('');
const sort: Ref<'data'|'amount'> = ref('data');
const reverseSort= ref(true);

const maxAvailablePage = computed(() => Math.min(pageNum.value, Math.ceil(filtered.value.length / props.itemsPerPage)));

const filtered = computed(() => props.movements.filter(any(filter.value)).sort(by(sort.value, reverseSort.value)));

const paged = computed(() =>  {
  const page = Math.min(pageNum.value, Math.ceil(filtered.value.length / props.itemsPerPage));
  return filtered.value.slice((page - 1) * props.itemsPerPage, page * props.itemsPerPage);
});

watch(() => filter.value, () => {
  resetPage(maxAvailablePage.value);
});

function resetPage(value: number) {
  pageNum.value = value;
}

function expiringLabel(strdate: string) {
  const now = DateTime.local();
  const date = parseDateTime(strdate);
  const diff = date.diff(now, ['months']).months;
  const expiring = date.plus({months: 15}).toFormat('dd/MM/yyyy');


  return diff < -12 ? _t(`- In scadenza il {0}`, [expiring]) : "";
}

defineExpose({
  resetPage,
});
</script>
