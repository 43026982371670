import type { App } from 'vue';

import Pf3Icon from './Pf3Icon.vue';

// Declare install function executed by Vue.use()
export function install(app: App) {
  app.component('Pf3Icon', Pf3Icon);
}

// Create module definition for Vue.use()
const plugin = {
  install,
};

export default plugin;

declare module 'vue' {
  export interface GlobalComponents {
    Pf3Icon: typeof Pf3Icon;
  }
}
