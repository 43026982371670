<template>
  <page
    v-model:filter="q"
    :name="$t('I tuoi prodotti')"
    :title="$t('I tuoi prodotti')"
    :empty="products.length == 0"
    :state="asyncComputed"
  >
    <pf-toolbar ref="toolbar" view="list" views="">
      <template #filter>
        <x-input v-model="q" type="search" :placeholder="$t('Filtra')" />
      </template>

      <pf-dropdown menu-right :text="$t('Raggruppa per') + ': ' + groupByOptions[groupBy]">
        <li v-for="(value, key) in groupByOptions" :key="key">
          <a role="menuitem" @click.prevent="groupBy = key">
            {{ value }}
          </a>
        </li>
      </pf-dropdown>
    </pf-toolbar>

    <p v-if="login.cod_pagamento == 'RD000'">{{ $t('I prodotti indicati come "Rinnovo previo pagamento" saranno rinnovati alla data di scadenza SOLO SE RICEVEREMO IL PAGAMENTO entro e non oltre la data di scadenza riportata di seguito.') }}</p>
    <p v-if="login.cod_pagamento != 'RD000'">{{ $t('Eventuali disdette o modifiche sono da comunicarsi entro e non oltre la data di scadenza riportata di seguito.') }}</p>

    <table v-for="(rgroup, index) in groupedProducts" :key="index" class="table">
      <thead>
        <tr>
          <th colspan="8">
            <h3>
              <template v-if="groupBy == 'renewal_date'">
                <template v-if="index == 'test'">{{ $t('Prodotti in prova') }}</template>
                <template v-else-if="index == 'scadenza3'">{{ $t('Scadranno nei prossimi tre mesi') }}</template>
                <template v-else-if="index == 'scadenza12'">{{ $t('Scadranno entro fine anno') }}</template>
                <template v-else-if="index == 'scadenzaFuture'">{{ $t('Altri con scadenza futura') }}</template>
                <template v-else-if="index == 'canceled'">{{ $t('Prodotti disdetti') }}</template>
                <template v-else>{{ $t('In Scadenza') }}</template>
              </template>
              <template v-else>{{ index }}</template>
            </h3>
          </th>
        </tr>
        <tr>
          <th>
            <sort-link v-if="groupBy !== 'domain'" v-model="s" v-model:reverse="r" field="dominio">
              {{ $t('Dominio') }}
            </sort-link>
            <template v-else>{{ $t('Dominio') }}</template>
          </th>
          <th>
            <sort-link v-model="s" v-model:reverse="r" field="nome">
              {{ $t('Nome') }}
            </sort-link>
          </th>
          <th class="price">
            <sort-link v-model="s" v-model:reverse="r" field="prezzo">
              {{ $t('Importo') }}
            </sort-link>
          </th>
          <th>{{ $t('Cadenza') }}</th>
          <th>
            <sort-link v-model="s" v-model:reverse="r" field="data_fine">
              {{ $t('Scadenza') }}
            </sort-link>
          </th>
          <th v-if="!company.id">
            {{ $t('Rinnovo') }}
          </th>
          <th v-if="!company.id">
            <pf-button
              v-if="groupedIDs[index].length > 1 && abilitaRinnovoGruppo()"
              x-small
              variant="primary"
              :to="{ name: 'product_renew', params: { id: groupedIDs[index].join('-') } }"
              class="btn-rinnovo-group"
            >
              {{ $t('Rinnova gruppo') }} ({{ groupedIDs[index].length }})
            </pf-button>
          </th>
          <th v-if="!company.id" style="max-width: 240px">
            {{ $t('Azioni') }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="product in rgroup" :key="product.id">
          <td>{{ product.dominio }}</td>
          <td>
            {{ product.nome }}<span v-if="product.qt > 1"> (x{{ product.qt }})</span>
            <template v-if="product.note_cliente">
              <br>
              {{ $t('Note') }}: {{ product.note_cliente }}
            </template>
            <template v-if="product.last_bw_upgrade">
              <br>
              <i>{{ $t('Upgrade di traffico automatico (in data {0})', [$date(product.last_bw_upgrade)]) }} - <template v-if="product.free_bw_upgrade">{{ $t('Primo mese gratuito') }}</template></i>
            </template>
          </td>
          <td class="price">
            {{ $currency(product.prezzo, login.currency) }}
            <template v-if="product.last_bw_upgrade">
              <br>
              <i v-if="product.free_bw_upgrade">{{ $currency(0, login.currency) }}</i>
              <i v-else>{{ $currency(product.bw_upgrade_price, login.currency) }}</i>
            </template>
          </td>
          <td>
            {{
              product.durata == 'Annuale'
                ? $t('Annuale')
                : product.durata == 'Mensile'
                  ? $t('Mensile')
                  : $t('Una Tantum')
            }}
          </td>
          <td>
            {{ $date(product.data_fine) }}
            <span v-if="!product.disdetto && product.daysToExpiration < 0 && login.cod_pagamento == 'RD000'" class="label label-danger"> {{ $t('Scaduto') }} </span>
            <span v-else-if="product.disdetto && product.free_period > 0 && product.daysToExpiration < 0" class="text-danger">{{ $t('Periodo di prova terminato - in cancellazione') }}</span>
            <span v-else-if="product.disdetto && product.free_period > 0 && product.daysToExpiration >= 0" class="text-success">{{ $t('Prodotto in prova') }}</span>
            <span v-else-if="product.disdetto && product.daysToExpiration < 0" class="text-danger">{{ $t('Scaduto') }}: {{ daysFromNow(product.data_fine) }}</span>
            <span v-else-if="product.daysToExpiration >= 0"> ({{ daysFromNow(product.data_fine) }})</span>
          </td>
          <td v-if="product.disdetto">
            {{ $t('Disdetto') }}
          </td>
          <td v-else-if="login.cod_pagamento == 'RD000'">
            {{ $t('Previo pagamento') }}
          </td>
          <td v-else-if="login.cod_pagamento != 'RD000' && !company.id && product.durata != 'Una tantum'" class="cell-toggle">
            <toggle
              :disabled="!login.grants.billing"
              :model-value="product.rinnovo_automatico"
              on-class="btn-primary"
              :on-text="$t('Automatico')"
              :on-value="true"
              :off-value="false"
              :off-text="$t('Su richiesta')"
              no-inline
              size="sm"
              @update:model-value="rinnovoAuto(product, $event)"
            />
            <form-help-tooltip v-if="!login.grants.billing" :text="$t('Non hai i permessi per modificare la tipologia di rinnovo del prodotto. Richiedili ad un utente amministratore.')" />
          </td>
          <td v-if="!company.id" class="btn-actions">
            <granted-link
              v-if="product.hasPendingPayments"
              :to="{ name: 'invoices' }"
              class="btn btn-xs btn-danger">
              {{ $t('Vai alla sezione fatture') }}
            </granted-link>
            <form-help-tooltip
              v-if="product.hasPendingPayments"
              :text="$t('La fattura nr. {0} che include il rinnovo di questo prodotto non è ancora stata saldata', [
                payments[product.id].fatture,
              ])"
            />
            <pf-tooltip v-else-if="product.prezzo && mostraRinnovo(product)">
              <pf-button
                :disabled="!abilitaRinnovo()"
                :to="{ name: 'product_renew', params: { id: product.id } }"
              >
                <circle-info-icon />
                {{ $t('Rinnova ora') }}
              </pf-button>
              <template #content>{{ renewTooltip() }}</template>
            </pf-tooltip>
          </td>
          <td class="btn-actions">
            <pf-button x-small @click="productNotePopup = product">
              {{ $t('Note') }}
            </pf-button>
            <template v-if="!company.id && !product.disdetto">
              {{ ' ' }}
              <pf-button
                v-if="product.durata != 'Una tantum'"
                x-small
                :disabled="!login.grants.billing"
                @click.prevent="changeAdministrativeState(product, 'non rinnovare')">
                {{ $t('Non rinnovare') }} {{ ' ' }}
                <form-help-tooltip v-if="!login.grants.billing" :text="$t('Non hai i permessi per disdire il prodotto. Richiedili ad un utente amministratore.')" />
              </pf-button>
              {{ ' ' }}
              <granted-link
                v-if="product.n_upgrades"
                :to="{ name: 'product_upgrade', params: { id: product.id } }"
                class="btn btn-xs btn-primary"
                disabled-message="Non hai i permessi per effettuare l'upgrade dell prodotto"
                :title="$t('Effettua upgrade del prodotto')"
              >
                {{ $t('Upgrade') }}
              </granted-link>
            </template>
            <template v-else-if="product.recoveryDays >= 0">
              <template v-if="product.disdetto && product.free_period">
                <pf-tooltip v-if="!product.hasPendingPayments && product.prezzo">
                  <granted-link
                    :to="{ name: 'product_renew', params: { id: product.id } }"
                    class="btn btn-xs btn-primary"
                    disabled-message="Non hai i permessi per confermare il prodotto. Richiedili ad un utente amministratore."
                  >
                    <triangle-exclamation-icon v-if="product.recoveryDays < 14" />
                    <info-icon v-else />
                    {{ $t('Conferma prodotto') }}
                  </granted-link>
                  <template #content>{{ $t('Clicca per procedere all\'acquisto di questo prodotto, i giorni rimasti per poterlo confermare sono {0}',[product.recoveryDays]) }}</template>
                </pf-tooltip>
              </template>
              <template v-else>
                <pf-tooltip v-if="!product.hasPendingPayments && product.prezzo">
                  <pf-button
                    :to="{ name: 'product_renew', params: { id: product.id } }"
                    x-small
                    variant="primary"
                  >
                    <triangle-exclamation-icon v-if="product.recoveryDays < 14" />
                    <info-icon v-else />
                    {{ $t('Recupera prodotto') }}
                  </pf-button>
                  <template #content>{{ $t('Clicca per procedere al recupero di questo prodotto, i giorni rimasti per poterlo recuperare sono {0}', [product.recoveryDays]) }}</template>
                </pf-tooltip>
                <pf-tooltip v-if="product.hasPendingPayments">
                  <pf-button :to="{ name: 'invoices' }" x-small>
                    <triangle-exclamation-icon v-if="product.recoveryDays < 14" />
                    <info-icon v-else />
                    {{ $t('Vai alla sezione fatture') }}
                  </pf-button>
                  <template #content>{{ $t('Per procedere al recupero di questo prodotto ti rimangono {0} giorni ma occorre prima saldare le rate in sospeso per la fattura nr. {1}', [product.recoveryDays, payments[product.id].fatture]) }}</template>
                </pf-tooltip>
              </template>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </page>

  <customer-note-popup
    v-if="productNotePopup"
    v-model:customer-note="productNotePopup.note_cliente"
    :product-id="productNotePopup.id"
    show
    @submit="clearCustomerNote"
    @close="clearCustomerNote"
  />
</template>

<style scoped>
.cell-toggle > * {
  margin-top: 3px;
}

.btn-actions .btn {
  display: inline-block;
  margin: 3px 0 3px 3px;
  min-width: 100px;
}

.price {
  text-align: right;
}
</style>

<script lang="ts">
import { setupAsyncComputed } from '@common/asyncComputed';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import { any, by } from '@common/utils';

import XInput from '@/components/x-input.vue';
import Page from '../components/page.vue';
import SortLink from '../components/sort-link.vue';
import Toggle from '../components/toggle.vue';
import GrantedLink from '../components/granted-link.vue';
import CustomerNotePopup from '../components/customer-note-popup.vue';
import InfoIcon from '@vue-patternfly/icons/info-icon';
import CircleInfoIcon from '@vue-patternfly/icons/circle-info-icon';
import TriangleExclamationIcon from '@vue-patternfly/icons/triangle-exclamation-icon';

import { useCompanyStore } from '@/store/company';
import { useLoginStore } from '@/store/login';
import { useAppStore } from '@/store/app';
import { type ProductOrder, ProductOrdersResource, Resource, type GenericResource } from '@/resources';
import { DateTime, Interval } from 'luxon';
import { parseDateTime, daysFromNow } from '@common/date';
import { defineComponent } from 'vue';
import { $t } from '@/i18n';

export async function changeAdministrativeState(prodotto: ProductOrder, state: "non rinnovare" | "attivo") {
  if (!prodotto) {
    return;
  }

  let confirmMessage = state === 'attivo'
    ? $t("Confermi di voler rinnovare il prodotto alla scadenza?")
    : $t("Confermi di voler disdire il prodotto alla scadenza?");
  confirmMessage += "\n\n" + $t("Inserire nome e cognome e confermare per procedere:");


  let responseMessage = state === 'attivo'
    ? $t("Prodotto confermato.")
    : $t("Prodotto impostato come disdetto.");
  responseMessage = `${$t('Operazione completata.')} ${responseMessage}`;

  const confirm = window.prompt(confirmMessage);

  if (!confirm) {
    return;
  }

  const app = useAppStore();

  try {
    await new ProductOrdersResource().put(prodotto.id, {
      stato_amministrativo: state,
      nominativo: confirm,
    });
    prodotto.disdetto = true;
    app.toast(responseMessage, 'success');
  } catch (e) {
    app.toast($t("Non è stato possibile completare l'operazione. Riprova più tardi o apri un ticket all'assistenza clienti"), 'warning');
  }
}

type GroupByOptions = 'renewal_date' | 'domain' | 'customer_note';

type ExtendedProductOrder = ProductOrder & {
  id: number;
  daysToExpiration: number;
  recoveryDays: number;
  hasPendingPayments: boolean;
};

const retentionDays = 9;
const groupByOptions = {
  'renewal_date': $t('Data di rinnovo'),
  'domain': $t('Nome a dominio'),
  'customer_note': $t('Note'),
} as Record<GroupByOptions, string>;

export default defineComponent({
  name: 'ProductsPage',

  components: {
    Page,
    SortLink,
    Toggle,
    GrantedLink,
    CustomerNotePopup,
    FormHelpTooltip,
    XInput,
    TriangleExclamationIcon,
    InfoIcon,
    CircleInfoIcon,
  },

  setup() {
    const login = useLoginStore();
    const company = useCompanyStore();
    return {
      login,
      company,
      ...setupAsyncComputed({
        products: {
          async get(this: any): Promise<ExtendedProductOrder[]> {
            const products = await new ProductOrdersResource().get();
            return products.map(this.assignProductInfo);
          },
          default: [] as ExtendedProductOrder[],
        },

        payments: {
          async get(this: any): Promise<GenericResource[]> {
            return new Resource('products_expiring_payments').get();
          },
          default: [] as GenericResource[],
        },
      }),
    };
  },

  data(this: void) {
    return {
      q: '', // search query/become-partner
      s: 'data_fine' as keyof ExtendedProductOrder, // sort by
      r: false, // reverse sort
      now: DateTime.now(),
      groupBy: 'renewal_date' as GroupByOptions,
      groupByOptions,
      productNotePopup: null as ExtendedProductOrder | null,
    };
  },

  computed: {
    sortedFilteredProducts(): ExtendedProductOrder[] {
      return this.products
        .filter(any(this.q))
        .sort(by(this.s, this.r));
    },

    groupedProductsByRenewalDate() {
      const grouped = {
        test: [] as ExtendedProductOrder[],
        scadenza: [] as ExtendedProductOrder[],
        scadenza3: [] as ExtendedProductOrder[],
        scadenza12: [] as ExtendedProductOrder[],
        scadenzaFuture: [] as ExtendedProductOrder[],
        canceled: [] as ExtendedProductOrder[],
      };

      const endOfMonth = this.now.endOf('month');
      const endOfYear = this.now.endOf('year');
      const nextMonth = this.now.startOf('month').plus({ months: 1 });
      const threeMonthsFuture = this.now.endOf('month').plus({ months: 3 });

      for (const product of this.sortedFilteredProducts) {
        const dataFine = parseDateTime(product.data_fine);

        if (product.free_period > 0) {
          grouped.test.push(product);
          continue;
        }

        if (!product.scaduto && !product.disdetto) {
          // fine mese
          if (dataFine <= endOfMonth) {
            grouped.scadenza.push(product);
            continue;
          }
          // prossimi tre mesi
          if (Interval.fromDateTimes(nextMonth, threeMonthsFuture).contains(dataFine)) {
            grouped.scadenza3.push(product);
            continue;
          }

          // fine anno
          if (dataFine <= endOfYear) {
            grouped.scadenza12.push(product);
            continue;
          }

          // altri
          grouped.scadenzaFuture.push(product);
          continue;
        }

        grouped.canceled.push(product);
      }

      // unset empty array keys
      for (const k of Object.keys(grouped) as (keyof typeof grouped)[]) {
        if (grouped[k].length == 0) {
          Reflect.deleteProperty(grouped, k);
        }
      }

      return grouped;
    },

    groupedProductsByDomain() {
      const grouped: Record<string, ExtendedProductOrder[]> = {};

      let dominio = '';
      for (const product of this.sortedFilteredProducts) {
        dominio = product.dominio ?? '';
        if (!grouped[dominio]) {
          grouped[dominio] = [product];
        } else {
          grouped[dominio].push(product);
        }
      }

      return Object.fromEntries(Object.entries(grouped).sort());
    },

    groupedProductsByCustomerNote() {
      const grouped: Record<string, ExtendedProductOrder[]> = {};

      for (const product of this.sortedFilteredProducts) {
        if (!grouped[product.note_cliente ?? $t('Senza note')]) {
          grouped[product.note_cliente ?? $t('Senza note')] = [product];
        } else {
          grouped[product.note_cliente ?? $t('Senza note')].push(product);
        }
      }

      return Object.fromEntries(Object.entries(grouped).sort());
    },

    groupedProducts() {
      if (this.groupBy == 'domain') {
        return this.groupedProductsByDomain;
      }

      if (this.groupBy == 'customer_note') {
        return this.groupedProductsByCustomerNote;
      }

      return this.groupedProductsByRenewalDate;
    },

    groupedIDs() {
      const ids: Record<string, number[]> = {};
      for (const k of Object.keys(this.groupedProducts)) {
        ids[k] = this.groupedProducts[k as keyof typeof this.groupedProducts]
          .filter(p => p.prezzo && !p.hasPendingPayments)
          .map(p => p.id);
      }
      return ids;
    },

    scadutiDisdettiIDs() {
      const ids = {
        scaduti: [] as number[],
        disdetti: [] as number[],
      };
      for (const k of Object.keys(ids) as (keyof typeof ids)[]) {
        ids[k] = this.products
          .filter(
            p => (k == 'scaduti' ? p.scaduto : p.disdetto) && p.prezzo && p.recoveryDays >= 0 && !p.hasPendingPayments,
          )
          .map(p => p.id);
      }
      return ids;
    },

    pendingPaymentsIDs() {
      return Object.keys(this.payments).map(Number);
    },
  },

  methods: {
    assignProductInfo(p: ProductOrder): ExtendedProductOrder {
      const daysToExpiration = parseDateTime(p.data_fine).diff(this.now, ['days']).days;
      const hasPendingPayments = this.pendingPaymentsIDs.includes(p.id);

      return {
        ...p,
        id: Number(p.id),
        daysToExpiration,
        recoveryDays: Math.round(daysToExpiration + retentionDays),
        hasPendingPayments,
      };
    },

    async rinnovoAuto(prodotto: ProductOrder, automatico: boolean) {
      if (!prodotto || prodotto.disdetto) {
        return;
      }

      const confirm = window.prompt(
        $t('Inserire nome e cognome e confermare per procedere:'),
      ); /* eslint no-alert: off */

      if (!confirm) {
        return;
      }

      const app = useAppStore();

      try {
        const response = await new ProductOrdersResource().put(prodotto.id, {
          rinnovo_automatico: Boolean(automatico),
          nominativo: confirm,
        });
        prodotto.rinnovo_automatico = response.rinnovo_automatico;
        if (prodotto.rinnovo_automatico) {
          app.toast($t('Rinnovo automatico del prodotto attivato'), 'success');
        } else {
          app.toast($t('Rinnovo automatico del prodotto disattivato'), 'success');
        }
      } catch (e) {
        app.toast(
          $t(
            "Non è stato possibile aggiornare l'impostazione per il rinnovo automatico sul prodotto, riprova più tardi o apri un ticket all'assistenza clienti",
          ),
          'warning',
        );
      }
    },

    abilitaRinnovoGruppo() {
      return this.login.cod_pagamento == 'RD000' && this.abilitaRinnovo();
    },

    mostraRinnovo(prodotto: ProductOrder) {
      return this.login.cod_pagamento == 'RD000' || !prodotto.rinnovo_automatico;
    },

    abilitaRinnovo() {
      return this.login.grants.billing || this.login.grants.sales;
    },

    renewTooltip() {
      return this.abilitaRinnovo()
        ? $t('Verrai rediretto alla pagina di rinnovo')
        : $t('Potrai procedere al rinnovo del prodotto nei tre mesi precedenti alla sua scadenza');
    },

    clearCustomerNote() {
      this.productNotePopup = null;
    },

    daysFromNow,

    changeAdministrativeState,
  },
});
</script>
