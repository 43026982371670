<template>
  <form class="input-group" @submit.prevent="submit">
    <input :id="id" :type="type" :disabled="!active || undefined" :required="required" :value="modelValue" class="form-control" @input="$emit('update:modelValue', ($event.target as HTMLInputElement).value)">
    <div class="input-group-btn">
      <pf-button type="submit" :disabled="disabledSubmit || undefined" v-bind={title}>
        <pf-spinner v-if="saving" inline size="sm" />
        <save-icon v-else />
      </pf-button>
    </div>
  </form>
</template>

<style lang="scss" scoped>
.input-group-btn .spinner-inline.spinner-sm {
  margin-right: 0;
  margin-bottom: -4px;
}
</style>

<script lang="ts" setup>
import { computed } from 'vue';
import { ref } from 'vue';
import SaveIcon from '@vue-patternfly/icons/save-alt-icon';

const props = withDefaults(defineProps<{
  id?: string;
  name?: string;
  modelValue?: string;
  title?: string;
  type?: string;
  disabled?: boolean;
  required?: boolean;
  onSave?: (value: string, name: string | undefined) => Promise<void>;
}>(), {
  modelValue: '',
  title: 'Salva',
  type: 'text',
  required: true,
});

const emit = defineEmits<{
  (e: 'update:modelValue', value: string): void
  (e: 'submit', event: Event): void
  (e: 'save', value: string, name: string | undefined): void
}>();

const original = ref(props.modelValue);
const saving = ref(false);

const active = computed(() => !saving.value && !props.disabled);
const disabledSubmit = computed(() => original.value === props.modelValue || saving.value || props.disabled);

async function submit(e: Event) {
  if (disabledSubmit.value) {
    return;
  }

  emit('submit', e);

  if (typeof props.onSave !== 'undefined') {
    saving.value = true;
    try {
      await props.onSave(props.modelValue, props.name);
      original.value = props.modelValue;
    } finally {
      saving.value = false;
    }
  }
}
</script>
