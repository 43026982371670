<template>
  <page :name="$t('Upgrade')" :title="$t('Upgrade Prodotto')" :state="asyncComputed">
    <template #breadcrumbs>
      <router-link v-slot="{ navigate }" custom :to="{ name: 'products' }">
        <li>
          <a @click="navigate">{{ $t('Prodotti') }}</a>
        </li>
      </router-link>
    </template>

    <payment-report
      :result="Number($route.query.res)"
      :error="$pop($route.query.err)"
      :success="$t('Upgrade servizi confermato')"
      route="products"
      :link="$t('Torna alla lista dei prodotti')"
    >
      <div v-if="valore_storno || upgrades.length === 0" class="alert alert-danger" role="alert">
        <strong>{{ $t('ATTENZIONE') }}</strong>
        {{ $t("Non è possibile effettuare l'upgrade automatico con relativo storno per questo prodotto.") }}<br>
        <i18n-t keypath="Aprire un {0} al supporto clienti specificando l'errore per maggiore assistenza." tag="span">
          <router-link :to="{ name: 'tickets' }">
            {{ $t('ticket') }}
          </router-link>
        </i18n-t>
      </div>

      <h4>
        {{ product.descrizione }}
        <template v-if="product.qt > 1"> (x{{ product.qt }}) </template>
      </h4>

      <form
        v-if="valore_storno >= 0 && upgrades.length > 0"
        method="post"
        action="/api/pay_product_upgrade"
        @submit="submit"
      >
        <fieldset :disabled="!product.id">
          <div class="form-group">
            <label class="control-label">{{ $t('Seleziona il nuovo prodotto') }}</label>
            <div v-for="pu in upgrades" :key="pu.id" class="radio">
              <label>
                <input v-model="id_product_to" type="radio" name="id_product_to" :value="pu.id">
                {{ pu.nome }} ({{ pu.durata }}) <small>{{ pu.codice }}</small> <strong>{{ formatCurrency(pu.prezzo, login.currency) }}</strong>
              </label>
            </div>
          </div>

          <template v-if="selected">
            <input type="hidden" name="id_migrated_from" :value="product.id">

            <pf-notification>
              {{ $t('Il prodotto "{0}" verrà disdetto', [product.nome]) }}.<br>
              {{ $t('Procederemo all\'upgrade a "{0}".', [selected.nome]) }}
            </pf-notification>

            <div style="max-width: 800px">
              <h3>{{ $t('Riepilogo:') }}</h3>
              <ul>
                <li class="row">
                  <span class="col-xs-12 col-sm-4"><strong>{{ $t('Costo upgrade (IVA esclusa)') }}:</strong></span>
                  <span class="col-xs-12 col-sm-2 value-cell">{{ formatCurrency(selected.prezzo, login.currency) }}</span>
                </li>

                <li v-if="valore_storno > 0" class="row">
                  <span class="col-xs-12 col-sm-4"><strong>{{ $t('Storno periodo non usufruito (IVA inclusa)') }}:</strong></span>
                  <span class="col-xs-12 col-sm-2 value-cell">-{{ formatCurrency(valore_storno, login.currency) }}</span>
                </li>

                <li v-if="totale_da_pagare > 0" class="row">
                  <span class="col-xs-12 col-sm-4"><strong>{{ $t('Totale (IVA inclusa)', []) }}</strong></span>
                  <span class="col-xs-12 col-sm-2 value-cell">{{ formatCurrency(totale_da_pagare, login.currency) }}</span>
                </li>
              </ul>
            </div>

            <payment-methods
              v-model="method"
              enable-cashback
              :totale-offerta-ivato="totale_da_pagare"
              :currency="login.currency"
            />

            <pf-button type="submit" variant="primary" :disabled="!enabled">
              <template v-if="method === 'custom'">{{ $t('Conferma l\'upgrade') }}</template>
              <template v-else>{{ $t('Procedi al pagamento') }}</template>
            </pf-button>{{ ' ' }}<em v-if="method !== 'custom'">{{ $t("Verrai reindirizzato alla pagina di pagamento. L'operazione può durare qualche secondo.") }}</em>
          </template>
        </fieldset>
      </form>
    </payment-report>
  </page>
</template>

<style scoped>
.btn-actions .btn {
  text-align: left;
  min-width: 280px;
  width: auto;
  margin: 5px 0;
}
.btn-actions .btn.btn-primary {
  background-color: #204d74;
  border: 0;
}
.product-notice {
  margin: 0 5px 20px;
}

ul {
  list-style-type: none;
  padding: 0;
}

.value-cell {
  text-align: right;
}
</style>

<script lang="ts">
import { setupAsyncComputed } from '@common/asyncComputed';
import { formatCurrency } from '@common/utils';
import Page from '../components/page.vue';
import PaymentReport from '../components/payment-report.vue';
import PaymentMethods, { type Methods} from '../components/payment-methods.vue';
import { useLoginStore } from '@/store/login';
import { usePaypalStore } from '@/store/paypal';
import { ProductOrdersResource, type ProductOrderDetail } from '@/resources';
import { defineComponent } from 'vue';

type Product = Exclude<ProductOrderDetail, 'upgradable_products'>;

export default defineComponent({
  name: 'ProductUpgradePage',

  components: {
    Page,
    PaymentReport,
    PaymentMethods,
  },

  setup() {
    const login = useLoginStore();
    return {
      login,
      ...setupAsyncComputed({
        product:  {
          async get(this: any): Promise<Product> {
            const products = await new ProductOrdersResource().get(this.$route.params.id);

            if (!products?.length) {
              this.upgrades = [];
              return {} as Product;
            }

            const product = products[0];
            this.upgrades = product.upgradable_products;
            Reflect.deleteProperty(product, 'upgradable_products');
            return product;
          },
          default: {} as Product,
        },
      }),
    };
  },

  data() {
    return {
      method: undefined as Methods | undefined,
      id_product_to: null as number | null,
      upgrades: [] as ProductOrderDetail['upgradable_products'],
    };
  },

  computed: {
    enabled() {
      return this.method && this.selected && this.product.id;
    },

    selected() {
      return this.upgrades.find(p => p.id == this.id_product_to);
    },

    totale_da_pagare() {
      return (this.selected ? this.selected.importo : 0) - this.valore_storno;
    },

    valore_storno() {
      return this.product?.valore_storno ?? 0;
    },
  },

  methods: {
    formatCurrency,

    submit(e: Event) {
      if (!this.enabled) {
        e.preventDefault();
      }

      if (this.method === 'axerve-paypal') {
        const paypal = usePaypalStore();
        paypal.save();
      }
    },
  },
});
</script>
