<template>
  <page :name="_t('Profilo')" hide-breadcrumbs :loading="anagraficaState.updating">
    <form v-if="!anagraficaState.updating" role="form" @submit.prevent="submit">
      <fieldset>
        <div class="row">
          <div class="form-group col-md-6 col-lg-4">
            <label for="anagraficaNome">{{ _t('Nome') }}</label>
            <x-input
              id="anagraficaNome"
              v-model="anagrafica.firstname"
              name="firstname"
              type="text"
              maxlength="250"
              autocomplete="firstname"
            />
          </div>
          <div class="form-group col-md-6 col-lg-4">
            <label for="anagraficaCognome">{{ _t('Cognome') }}</label>
            <x-input
              id="anagraficaCognome"
              v-model="anagrafica.lastname"
              name="lastname"
              type="text"
              maxlength="250"
              autocomplete="lastname"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6 col-lg-4">
            <label for="anagraficaTelefono">{{ _t('Telefono') }}</label>
            <x-input id="anagraficaTelefono" v-model="anagrafica.phone" type="tel" maxlength="20" />
          </div>

          <div class="form-grou col-md-6 col-lg-4">
            <label for="anagraficaMobile">{{ _t('Cellulare') }}</label>
            <x-input
              id="anagraficaMobile"
              v-model="anagrafica.mobile"
              name="mobile"
              type="tel"
              maxlength="20"
              autocomplete="tel"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6 col-lg-4">
            <label for="anagraficaEmail">{{ _t('Email') }} *</label>
            <x-input
              id="anagraficaEmail"
              v-model="anagrafica.mail"
              name="mail"
              type="email"
              required
              maxlength="100"
              autocomplete="email"
            />
            <div
              v-if="originalMail !== anagrafica.mail && login.username === originalMail"
              class="alert alert-info"
              role="alert"
            >
              <small>
                <strong>{{ _t('IMPORTANTE') }}</strong>:
                {{ _t("il nuovo indirizzo email verrà usato anche come username per accedere all'area riservata, al posto di {0}", [login.username]) }}.
              </small>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="form-group col-md-6 col-lg-4">
            <template v-if="login.grants.admin">
              <label>{{ _t('Notifiche Email') }}</label>
            </template>
            <template v-else>
              <label>{{ _t('Notifiche Email a {0}', [login.azienda]) }}</label>
              <div class="alert alert-info" role="alert">
                <small>
                  {{ _t('Puoi solamente rimuovere eventuali notifiche attive.') }}
                  <br>
                  {{ _t("L'aggiunta deve essere richiesta direttamente a {0}.", [login.azienda]) }}
                </small>
              </div>
            </template>
            <p>
              <toggle
                v-model="anagrafica.email_subscriptions"
                :disabled="!login.grants.admin && !originalSubscriptions.includes('technical')"
                on-value="technical"
              >
                {{ _t('Tecniche') }}
              </toggle>
              &nbsp;
              <form-help-tooltip
                :text="_t('Riceve in copia le notifiche tecniche (es. tickets, notifiche di sistema)')"
              />
            </p>
            <p>
              <toggle
                v-model="anagrafica.email_subscriptions"
                :disabled="!login.grants.admin && !originalSubscriptions.includes('billing')"
                on-value="billing"
              >
                {{ _t('Amministrative') }}
              </toggle>
              &nbsp;
              <form-help-tooltip
                :text="_t('Riceve in copia le notifiche amministrative (es. fatture, scadenze)')"
              />
            </p>
            <p>
              <toggle
                v-model="anagrafica.email_subscriptions"
                :disabled="!login.grants.admin && !originalSubscriptions.includes('marketing')"
                on-value="marketing"
              >
                {{ _t('Commerciali') }}
              </toggle>
              &nbsp;
              <form-help-tooltip
                :text="_t('Riceve in copia le notifiche commerciali (es. offerte dedicate)')"
              />
            </p>
          </div>
          <div class="form-group col-md-6 col-lg-4">
            <label>{{ _t('Newsletter') }}</label>
            <div>
              <toggle v-model="anagrafica.newsletter_subscription" :on-value="true" :off-value="false">
                {{ _t('Iscritto alla nostra newsletter') }}
              </toggle>
            </div>
          </div>
        </div>

        <div class="pull-right">
          <em>* {{ _t('Campo obbligatorio') }}</em>
        </div>
        <div class="input-group-btn">
          <pf-button variant="primary" type="submit" :disabled="sending">
            <span v-if="sending" class="spinner spinner-inline spinner-sm" />
            {{ _t('Aggiorna i tuoi dati') }}
          </pf-button>
        </div>
      </fieldset>
    </form>

    <hr>

    <h2>{{ _t('Sicurezza') }}</h2>

    <h4>{{ _t('Cambia Password') }}</h4>
    <reset-password-form v-show="!anagraficaState.updating"/>

    <h4>{{ _t('Autenticazione a due fattori') }}</h4>

    <toggle v-show="!anagraficaState.updating" :model-value="anagrafica.twofa" @update:model-value="setTwoFA">
      {{ _t("Imposta l'account per ricevere codici di autenticazione tramite un'applicazione dedicata") }}
    </toggle>

    <twofa-enable-modal v-if="showEnableTwoFA" v-model:show="showEnableTwoFA" v-model:enabled="anagrafica.twofa" />
    <twofa-disable-modal v-if="showDisableTwoFA" v-model:show="showDisableTwoFA" v-model:enabled="anagrafica.twofa" />
  </page>
</template>

<style scoped>
h4 {
  margin-top: 2em;
}

hr {
  margin-top: 3em;
}
</style>

<script lang="ts" setup>
import { ref, type Ref } from 'vue';
import { $t as _t } from '@/i18n';
import Page from '../components/page.vue';
import ResetPasswordForm from '@/components/reset-password-form.vue';
import Toggle from '../components/toggle.vue';
import TwofaEnableModal from '../components/twofa-enable-modal.vue';
import TwofaDisableModal from '../components/twofa-disable-modal.vue';
import XInput from '@/components/x-input.vue';
import { asyncComputed } from '@common/asyncComputed';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import { useLoginStore } from '@/store/login';
import { type Profile, ProfilesResource } from '@/resources';
import { useAppStore } from '@/store/app';

const login = useLoginStore();
const originalMail = ref('');
const originalSubscriptions: Ref<string[]> = ref([]);
const sending = ref(false);
const showEnableTwoFA = ref(false);
const showDisableTwoFA = ref(false);

const defaultAnagrafica = {
  id: 0,
  title: null,
  firstname: null,
  lastname: null,
  street: null,
  postalcode: null,
  city: null,
  province: null,
  country: null,
  phone: null,
  mobile: null,
  mail: '',
  newsletter_subscription: false,
  email_subscriptions: [],
  grants: [],
  twofa: false,
} as Profile;

const { result: anagrafica, state: anagraficaState } = asyncComputed(async() => {
  if (!login.id) {
    return defaultAnagrafica;
  }
  const anagrafica = await new ProfilesResource().get(login.id);
  originalMail.value = anagrafica.mail;
  originalSubscriptions.value = [...anagrafica.email_subscriptions];
  return anagrafica;
}, defaultAnagrafica);

async function submit() {
  if (!anagrafica.value.id) {
    return;
  }

  // se non sono owner posso solo rimuovere le notifiche
  if (!login.grants.owner) {
    anagrafica.value.email_subscriptions = anagrafica.value.email_subscriptions.filter((type: string) =>
      originalSubscriptions.value.includes(type),
    );
  }

  const app = useAppStore();
  sending.value = true;
  try {
    await new ProfilesResource().put(anagrafica.value.id, anagrafica.value);
    app.toast(_t('Profilo aggiornato correttamente.'), 'success');
  } catch {
    app.toast(_t('Errore: profilo non aggiornato. Contatta il nostro supporto per maggiori informazioni.'), 'danger');
  } finally {
    sending.value = false;
  }

  anagraficaState.update();
  await login.refresh();
};

async function setTwoFA(enable: boolean) {
  if (enable) {
    showEnableTwoFA.value = true;
  } else {
    showDisableTwoFA.value = true;
  }
};
</script>
