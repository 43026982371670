<template>
  <div>
    <page :name="$t('Conto Artera')" :state="asyncComputed.account">
      <div class="row">
        <div v-if="login.sconto > 0" class="col-md-3">
          <div class="panel panel-default panel-metric">
            <div class="panel-heading">
              {{ $t('Saldo provvigioni') }}
            </div>
            <div class="panel-body">
              <h1>{{ $currency(totalProvvigioni, login.currency) }}</h1>
              <div>
                {{ $t('Sconto / Commissione') }}: <strong>{{ $percent(login.sconto / 100) }}</strong> -
                {{ $t('Commissione segnalazione') }}: <strong>{{ $percent(account.referral_percentage / 100) }}</strong>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="panel panel-default panel-metric">
            <div class="panel-heading">
              {{ $t('Saldo comarketing') }}
            </div>
            <div class="panel-body">
              <h1>{{ $currency(totalComarketing, login.currency) }}</h1>
              <div>
                {{ $t('Commissione comarketing') }}: <strong>{{ $percent(account.comarketing_percentage / 100) }}</strong>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-3">
          <div class="panel panel-default panel-metric">
            <div class="panel-heading">
              {{ $t('Saldo cashback') }}
            </div>
            <div class="panel-body">
              <h1>{{ $currency(totalCashback, login.currency) }}</h1>
              <div>
                {{ $t('Commissione cashback') }}: <strong>{{ $percent(account.cashback_percentage / 100) }}</strong>
              </div>
            </div>
          </div>
        </div>
      </div>


      <pf-tabs>
        <pf-tab key="provvigioni" :title="$t('Provvigioni')">
          <collect-commissions
            :enabled="enableCollectionProvvigioni"
            :currency="login.currency"
            :total="totalProvvigioni"
            :collect-from="account.collect_from"
            :iban="iban"
            @submit="resetProvvigioni"
          />

          <div v-if="login.is_reseller" class="form-group">
            {{ $t('Usa automaticamente il credito disponibile per il pagamento delle fatture') }}
            <div class="btn-group btn-group-flip inline-with-text">
              <btn-radio
                v-model="use_credit"
                class="btn-sm"
                active-class="btn-success"
                inactive-class="btn-default"
                :disabled="saving_use_credit"
              >
                {{ $t('Attivo') }}
              </btn-radio>
              <btn-radio
                v-model="use_credit"
                class="btn-sm"
                active-class="btn-danger"
                inactive-class="btn-default"
                :checked-value="false"
                :disabled="saving_use_credit"
              >
                {{ $t('Disattivo') }}
              </btn-radio>
            </div>
            <pf-spinner v-if="saving_use_credit" size="sm" inline />
          </div>

          <movements-list
            ref="commissionsList"
            :movements="provvigioni"
            :currency="login.currency"
          />
        </pf-tab>


        <pf-tab key="comarketing" :title="$t('Comarketing')">
          <div class="intro">
            <p>
              {{ $t('Ottieni benefici promuovendo: ogni volta che viene emessa una fattura a favore della tua azienda o a un cliente da te indicato, convertiamo una parte dell\'importo totale in credito comarketing.') }}
              <br>
              {{ $t('Tale credito verrà accreditato sul tuo conto marketing non appena la fattura verrà regolarmente saldata.') }}
            </p>
            <p>
              {{ $t('Potenzia la tua presenza sul mercato: impiega il tuo credito marketing per avviare iniziative promozionali e valorizzare la tua attività, collaborando con il marchio Artera per la diffusione dei nostri servizi di Hosting e Cloud Premium.') }}
              <br>
              {{ $t('Presentaci un piano dettagliato del tuo progetto includendo i costi previsti, e noi ci impegneremo a coprire fino al 50% di tali spese, o fino al raggiungimento del limite massimo disponibile sul tuo conto marketing.') }}
            </p>
            <p>
              {{ $t('Per procedere con la realizzazione del tuo progetto, necessitiamo delle tue fatture e dei comprovanti di pagamento.') }}
              <br>
              {{ $t('Il tuo credito marketing verrà convertito in commissioni "standard", che potrai poi fatturarci o usare per il pagamento dei servizi da noi forniti.') }}
            </p>
          </div>
          <collect-comarketing
            :currency="login.currency"
            :total="totalComarketing"
          />

          <movements-list
            :movements="comarketing"
            :currency="login.currency"
          />
        </pf-tab>

        <pf-tab :title="$t('Cashback')">
          <div class="intro">
            <p>{{ $t('Con il nostro programma Cashback celebrare ogni nuovo ordine è ancora più gratificante!') }}</p>
            <p>{{ $t('Al momento di un nuovo acquisto, ti offriamo la possibilità di ricevere un cashback, una parte dell\'importo speso che viene accreditata per scontare i tuoi futuri acquisti.') }}</p>
            <p>{{ $t('Questo sistema è pensato per premiare la tua fiducia e il continuo supporto, offrendoti un vantaggio immediato che puoi sfruttare per ridurre i costi dei servizi futuri.') }}</p>
            <p>
              {{ $t('Più investi, più puoi risparmiare: il nostro programma Cashback è un modo per dire grazie e per assicurarti che ogni tuo investimento con noi non solo sia apprezzato, ma anche ricompensato.') }}
              <br>
              {{ $t('Unisciti a noi in questo ciclo virtuoso di crescita e risparmio.') }}
            </p>
          </div>
          <i18n-t
            v-if="!login.compiledSurvey"
            keypath="La generazione del credito cashback non è attiva poiché non è stato compilato il nostro questionario annuale {0}">
            <pf-button variant="link" @click="showSurveyModal = true">{{ $t('Clicca qui per compilarlo') }}</pf-button>
          </i18n-t>
          <i18n-t
            v-else-if="cashbackAttivo && account.cashback_until"
            keypath="La generazione del credito cashback è attiva fino al {0}">
            <strong>{{ $date(account.cashback_until) }}</strong>
          </i18n-t>
          <template v-else-if="account.cashback_until">{{ $t("La generazione del credito cashback non è attualmente attiva") }}</template>

          <div v-else style="max-width: 800px;">
            <template v-if="!account.cashback_months.length">
              <p>{{ $t("Seleziona i 3 mesi dell'anno durante i quali desideri che venga generato il cashback.") }}</p>
              <div>
                <label v-for="month in 12" :key="month" class="col-md-3 capitalized">
                  <input
                    type="checkbox"
                    v-model="cashback_months"
                    :value="month"
                    name="cashback_months[]"
                    :checked="cashback_months.includes(month)"
                    :disabled="lockMonth(month)"
                  >
                  {{ new Date(0, month-1).toLocaleString(undefined, {month: 'long'}) }} <!-- Sistemare lingua -->
                </label>
              </div>
              <pf-button variant="primary" :disabled="saving_cashback_months" @click="saveCashBackMonths">
                {{ $t('Salva') }}
              </pf-button>
            </template>
            <template v-else>
              <p>{{ $t('Il cashback verrà generato, secondo le tue indicazioni, nei mesi di:') }}</p>
              <ul>
                <li v-for="month in account.cashback_months.sort((a, b) => a < b ? -1 : 1)" :key="month" class="capitalized">
                  {{ new Date(0, month-1).toLocaleString(undefined, {month: 'long'}) }}
                </li>
              </ul>
              <i18n-t keypath="{0} per modificare la preferenza selezionata." tag="p">
                <router-link :to="{ name: 'tickets' }">
                  {{ $t('Apri un ticket') }}
                </router-link>
              </i18n-t>
            </template>
          </div>

          <movements-list
            ref="list"
            :movements="cashback"
            :currency="login.currency"
          />
        </pf-tab>
      </pf-tabs>
    </page>

    <pf-modal
      v-if="showSurveyModal"
      show
      :title="$t('Compila il questionario')"
      outside-close
      confirm-button=""
      cancel-button=""
      @cancel="showSurveyModal = false">
      <introduction-form  variant="survey" @submit="submittedSurvey" />
    </pf-modal>
  </div>
</template>

<style>
.tab-content {
  padding: 20px 10px;
  border: 1px solid #ddd;
  border-top: 0;
}

.capitalized {
  text-transform: capitalize;
}

.intro {
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  .modal-dialog {
      width: 800px;
  }
}
</style>

<script lang="ts">
import { useAppStore } from '@/store/app';
import { $t } from '@/i18n';
import { type Ref, ref } from 'vue';
import { parseDateTime } from "@common/date";
import { DateTime } from 'luxon';
import { setupAsyncComputed } from '@common/asyncComputed';
import Page from '../components/page.vue';
import CollectCommissions from '../components/collect-commissions.vue';
import CollectComarketing from '../components/collect-comarketing.vue';
import IntroductionForm from '../components/introduction-form.vue';
import MovementsList from '../components/movements-list.vue';
import BtnRadio from '../components/btn-radio.vue';
import { useLoginStore } from '@/store/login';
import { AccountMovementsResource, CompanyResource, type AccountMovements, type AccountMovement } from '@/resources';
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'AccountMovementsPage',

  components: {
    Page,
    MovementsList,
    BtnRadio,
    CollectCommissions,
    CollectComarketing,
    IntroductionForm,
  },

  setup() {
    const login = useLoginStore();
    const commissionsList: Ref<InstanceType<typeof MovementsList> | undefined> = ref();

    return {
      login,
      commissionsList,
      ...setupAsyncComputed({
        account: {
          get(this: any) {
            return new AccountMovementsResource().get();
          },
          default: {
            movements: [],
            collect_from: 100,
            referral_percentage: 0,
            comarketing_percentage: 0,
            cashback_percentage: 0,
            cashback_until: '',
            cashback_months: [],
            iban: null,
          } as AccountMovements,
        },
      }),
    };
  },

  data() {
    return {
      use_credit: false,
      saving_use_credit: false,
      saving_cashback_months: false,
      iban: '',
      cashback_months: [] as number[],
      showSurveyModal: false,
    };
  },

  computed: {
    storedUseCredit() {
      return this.login.use_credit;
    },

    provvigioni() {
      return this.account.movements.filter(m => ['provvigioni', 'segnalazioni'].includes(m.tipo));
    },

    totalProvvigioni() {
      return this.totalByTipo(this.provvigioni);
    },

    enableCollectionProvvigioni() {
      return this.totalProvvigioni >= this.account.collect_from;
    },

    comarketing() {
      return this.account.movements.filter(m => m.tipo === 'comarketing');
    },

    totalComarketing() {
      return this.totalByTipo(this.comarketing);
    },

    cashback() {
      return this.account.movements.filter(m => m.tipo === 'cashback');
    },

    totalCashback() {
      return this.totalByTipo(this.cashback);
    },

    cashbackAttivo() {
      return this.account.cashback_until !== null && parseDateTime(this.account.cashback_until) >= DateTime.local();
    },
  },

  watch: {
    storedUseCredit: {
      immediate: true,
      handler() {
        this.use_credit = this.login.use_credit;
      },
    },

    'account.iban'(v: string) {
      this.iban = v;
    },

    async use_credit() {
      if (this.login.id_azienda === null || typeof this.use_credit == 'undefined' || this.use_credit == this.login.use_credit) {
        return;
      }
      this.saving_use_credit = true;
      try {
        await new CompanyResource().save(this.login.id_azienda, { use_credit: this.use_credit });
        this.login.use_credit = this.use_credit;
      } catch (e) {
        this.use_credit = this.login.use_credit;
      } finally {
        this.saving_use_credit = false;
      }
    },
  },

  methods: {
    totalByTipo(movements: AccountMovement[]): number {
      return movements.reduce((t, i) => t + i.amount, 0);
    },

    resetProvvigioni() {
      this.asyncComputed.account.update();
      this.commissionsList?.resetPage(1);
    },

    lockMonth(month: number) {
      if (this.cashback_months.includes(month)) {
        return false;
      }
      return this.cashback_months.length >= 3;
    },

    submittedSurvey() {
      this.showSurveyModal = false;
      this.login.refresh();
    },

    async saveCashBackMonths() {
      const app = useAppStore();

      if (this.login.id_azienda === null || this.account.cashback_months.length || this.saving_cashback_months) {
        return;
      }

      if (this.cashback_months.length !== 3) {
        alert('Seleziona 3 mesi');
        return;
      }

      try {
        this.saving_cashback_months = true;
        await new CompanyResource().save(this.login.id_azienda, { cashback_months: this.cashback_months });
        app.toast($t('Preferenza salvata.'), 'success');
      } catch (e) {
        return;
      } finally {
        this.saving_cashback_months = false;
      }
      this.account.cashback_months = this.cashback_months;
    },
  },
});
</script>
