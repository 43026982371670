<template>
  <pf-modal
    :show="show"
    :title="$t('Note')"
    confirm-button=""
    cancel-button=""
    @cancel="onCancel"
  >
    <x-form
      xhr
      encoding="json"
      method="put"
      :action="`/rest/product_orders/${productId}`"
      :submit-text="$t('Salva')"
      @submit="onSubmit"
    >
      <div class="form-group">
        <x-input
          v-model="managedCustomerNote"
          name="note_cliente"
          type="text"
          required
        />
      </div>
    </x-form>
  </pf-modal>
</template>

<script lang="ts" setup>
import { useManagedProp } from '@common/use';
import XForm from '../components/x-form.vue';
import XInput from './x-input.vue';
import { ref } from 'vue';

defineOptions({
  name: 'CustomerNotePopup',
});

interface ModalProps {
  onClose?: (() => any) | undefined;
  onConfirm?: (() => any) | undefined;
};

interface Props extends /* @vue-ignore */ ModalProps {
  show?: boolean;
  productId: number;
  customerNote?: string | null;
}

const props = defineProps<Props>();

const emit = defineEmits<{
  (name: 'submit'): void;
  (name: 'cancel'): void;
  (name: 'update:customerNote'): void;
}>();

const initialValue = ref(props.customerNote);
const managedCustomerNote = useManagedProp('customerNote', '');

function onSubmit() {
  emit('submit');
}

function onCancel() {
  managedCustomerNote.value = initialValue.value ?? '';
  emit('cancel');
}
</script>
