<template>
  <pf-list-view :rows="(offerte as any)" :items-per-page="0" key-name="name" stacked class="offers-list">

    <template #default="{ row }">
      <pf-list-item>
        <template #heading>
          <div class="offers-date">
            {{ $date(row.data) }}
          </div>
          <div v-if="row.titolo_offerta">
            {{ row.titolo_offerta }}
          </div>
          <div class="offers-number">
            {{
              row.stato_offerta
                ? $t('Offerta nr. {0} intestata a: {1}', [row.id, row.ragione_sociale])
                : $t('Ordine nr. {0} intestata a: {1}', [row.id, row.ragione_sociale])
            }}
          </div>
        </template>
        <template #additional-info>
          <pf-list-item-additional-info expandable>
            {{ row.stato_offerta ? $t('Visualizza dettaglio offerta') : $t('Visualizza riepilogo ordine') }}

            <template #expansion>
              <offer-detail :offerta="row" />

              <div class="detail-buttons">
                <pf-button
                  v-if="row.stato_offerta && row.stato_offerta != 'rifiutata'"
                  :disabled="!row.enable_orders"
                  variant="danger"
                  @click="rejectedId = row.id"
                >
                  <ban-icon />
                  {{ $t('Rifiuta offerta') }}
                  <form-help-tooltip v-if="!row.enable_orders" :text="$t('Non hai i permessi per rifiutare quest\'offerta. Richiedili ad un utente amministratore dell\'azienda intestataria.')" />
                </pf-button>

                <a target="_blank" :href="`/rest/offer-pdf/${row.id}`" class="btn btn-primary btn-middle">
                  <file-pdf-icon />
                  {{ $t('Scarica PDF') }}
                </a>

                <pf-tooltip>
                  <template #content>{{ $t('Il PDF generato non conterrà le indicazioni relative ai tuoi sconti rivenditore') }}</template>

                  <a
                    v-if="login.is_reseller"
                    target="_blank"
                    :href="`/rest/offer-pdf/${row.id}?forceNoSconto=1`"
                    class="btn btn-primary btn-middle"
                  >
                    <file-pdf-icon />
                    {{ $t('Scarica PDF senza sconti') }}
                  </a>
                </pf-tooltip>

                <granted-link
                  v-if="row.stato_ordine != 'concluso' && row.stato_offerta != 'rifiutata'"
                  :force-disabled="!row.enable_orders"
                  :to="{ name: 'confirm_offer', params: { id: row.id } }"
                  :class="{'btn-success': row.enable_orders}"
                  :disabled-message="$t('Non hai i permessi per confermare l\'offerta. Richiedili ad un utente amministratore dell\'azienda intestataria.')"
                  class="btn"
                >
                  {{ $t('Conferma ordine') }}
                </granted-link>

              </div>
            </template>
          </pf-list-item-additional-info>
        </template>
      </pf-list-item>
    </template>

    <template #action="{ row }">
      <div class="price">
        <div class="amount">
          {{ $currency(row.gran_totale, row.currency) }}
        </div>
        <div v-if="row.gran_totale_ivato - row.gran_totale > 0">+ {{ $t('IVA') }}</div>
      </div>
      <div class="buttons">
        <a target="_blank" :href="`/rest/offer-pdf/${row.id}`" class="btn btn-primary btn-middle">
          <file-pdf-icon />
          {{ $t('Scarica PDF') }}
        </a>

        <granted-link
          v-if="row.stato_ordine != 'concluso' && row.stato_offerta != 'rifiutata'"
          :force-disabled="!row.enable_orders"
          :to="{ name: 'confirm_offer', params: { id: row.id } }"
          :class="{'btn-success': row.enable_orders}"
          :disabled-message="$t('Non hai i permessi per confermare l\'offerta. Richiedili ad un utente amministratore dell\'azienda intestataria.')"
          class="btn btn-middle"
        >
          {{ $t('Conferma ordine') }}
        </granted-link>

        <pf-button
          v-if="row.stato_offerta && row.stato_offerta != 'rifiutata'"
          :disabled="!row.enable_orders"
          variant="danger"
          @click="rejectedId = row.id"
        >
          <ban-icon />
          {{ $t('Rifiuta offerta') }}
          <form-help-tooltip v-if="!row.enable_orders" :text="$t('Non hai i permessi per rifiutare quest\'offerta. Richiedili ad un utente amministratore dell\'azienda intestataria.')" />
        </pf-button>
      </div>
    </template>
  </pf-list-view>
  <pf-modal
    v-if="rejectedId"
    show
    :title="$t('Rifiuta l\'offerta')"
    outside-close
    confirm-button=""
    cancel-button=""
    @cancel="rejectedId = undefined"
  >
    <x-form submit-text="" @submit="submit">
      <div class="form-group">
        <label>{{ $t('Motivazione rifiuto') }} *</label> {{ ' ' }}

        <select v-model="motivazione_rifiuto" name="motivazione_rifiuto" class="form-control" required>
          <option value="prezzo">{{ $t('Prezzo superiore alle aspettative') }}</option>
          <option value="altro fornitore">{{ $t('Ho scelto un altro fornitore') }}</option>
          <option value="tempistiche">{{ $t('Tempistiche di risposta commerciali non adeguate') }}</option>
          <option value="non più necessario">{{ $t('I prodotti richiesti non sono più necessari') }}</option>
          <option value="altro">{{ $t('Altro') }}</option>
        </select>

        <pf-button type="submit" variant="primary" class="reject-submit">{{ $t('Rifiuta') }}</pf-button>
      </div>
    </x-form>
  </pf-modal>
</template>

<style lang="scss">
.offers-date {
  font-size: 0.8em;
  margin-bottom: 5px;
}

.offers-number {
  font-size: 0.8em;
  margin-top: 15px;
}

.offers-list {
  .list-view-pf-additional-info-item {
    text-align: left;
    line-height: 1.1em;
    font-size: 1.2em;
    font-weight: normal;
  }

  .list-view-pf-actions {
    display: flex;
    align-items: center;
  }
}

.product-list {
  list-style-type: none;
  padding: 0;
}

.buttons {
  display: flex;
  align-content: right;
  justify-content: space-between;
  flex-direction: column;

  .btn-middle {
    margin-bottom: 10px;
  }

  button {
    margin-left: 0px;
  }
}

.price {
  text-align: center;
  min-width: 200px;
}

.amount {
  font-size: 1.6em;
}

.detail-buttons {
  text-align: right;

  .btn {
    margin: 10px 10px 0 0;
  }

  .type {
    float: left;
    padding-top: 15px;
  }
}

.reject-submit {
  margin-top: 10px;
}

@media (max-width: 500px) {
  .offers-list {
    .list-view-pf .list-group-item {
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }

    .list-view-pf-main-info {
      width: 100%;
    }

    .list-view-pf-actions {
      margin-top: 0;
      margin-left: 0;
    }
  }
}

@media (max-width: $screen-xs-max) {
  .offers-list .list-view-pf-actions {
    justify-content: space-between;
    flex-direction: column;
  }

  .buttons {
    margin-top: calc($grid-gutter-width / 4);
  }
}

@media (min-width: $screen-sm-min) {
  .price {
    padding-right: calc($grid-gutter-width / 4);
  }
}
</style>

<script lang="ts" setup>
import {ref, type Ref } from 'vue';
import type { Offer } from '@/resources';
import { useLoginStore } from '@/store/login';
import { OffersResource } from '@/resources';
import XForm from '../components/x-form.vue';

import OfferDetail from './offer-detail.vue';
import GrantedLink from './granted-link.vue';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import FilePdfIcon from '@vue-patternfly/icons/file-pdf-icon';
import BanIcon from '@vue-patternfly/icons/ban-icon';

defineProps<{
  offerte: Offer[];
  loading?: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:loading', value: boolean): void
  (e: 'change-state'): void
}>();

type MotivazioniRifiuto = 'prezzo' | 'altro fornitore' | 'tempistiche' | 'non più necessario' | 'altro';

const login = useLoginStore();
const rejectedId: Ref<number | undefined> = ref();
const motivazione_rifiuto: Ref<MotivazioniRifiuto | undefined> = ref();

async function submit() {
  if (rejectedId.value && motivazione_rifiuto.value) {
    emit('update:loading', true);
    await new OffersResource().put(rejectedId.value, { stato: 'rifiutata', motivazione_rifiuto: motivazione_rifiuto.value });

    rejectedId.value = undefined;
    emit('update:loading', false);
    emit('change-state');
  }
}
</script>
