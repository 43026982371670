<template>
  <form role="form" @submit.prevent="submit">
    <fieldset>
      <slot />

      <div v-if="!user.is_owner" class="row">
        <div class="form-group col-md-6 col-lg-4">
          <label>{{ $t('Rendi Amministratore') }}</label>
          <p>
            <toggle v-model="user.grants" on-value="admin" name="grants[]" @update:model-value="change">
              {{ $t('Amministratore') }}
            </toggle>
            {{ ' ' }}
            <form-help-tooltip :text="$t('Concedi privilegi di amministrazione di tutti i servizi e visione dei dati aziendali')" />
          </p>
        </div>
      </div>

      <div class="row">
        <div v-if="!user.is_owner" class="form-group col-md-6 col-lg-4">
          <label>{{ $t('Oppure concedi questi privilegi') }}</label>
          <p>
            <toggle v-model="user.grants" :disabled="user.grants.includes('admin')" on-value="billing" name="grants[]" @update:model-value="change">
              {{ $t('Amministrativi') }}
            </toggle>
            {{ ' ' }}
            <form-help-tooltip :text="$t('Concedi privilegi di tipo amministrativo (es. pagamenti, modifica dati aziendali)')" />
          </p>
          <p>
            <toggle v-model="user.grants" :disabled="user.grants.includes('admin')" on-value="sales" name="grants[]" @update:model-value="change">
              {{ $t('Commerciali') }}
            </toggle>
            {{ ' ' }}
            <form-help-tooltip :text="$t('Concedi privilegi di tipo commerciale (es. conferma offerte, modifica dati aziendali)')" />
          </p>
          <p>
            <toggle v-model="user.grants" :disabled="user.grants.includes('admin')" on-value="developer" name="grants[]" @update:model-value="change">
              {{ $t('Tecnici') }}
            </toggle>
            {{ ' ' }}
            <form-help-tooltip :text="$t('Concedi privilegi di tipo tecnico (es. password, modifica DNS)')" />
          </p>
        </div>

        <div class="form-group col-md-6 col-lg-4">
          <label>{{ $t('Notifiche Email') }}</label>
          <p>
            <toggle v-model="user.emailSubscriptions" on-value="billing" name="email_subscriptions[]" @update:model-value="change">
              {{ $t('Amministrative') }}
            </toggle>
            {{ ' ' }}
            <form-help-tooltip :text="$t('Riceve via mail le notifiche amministrative (es. fatture, scadenze)')" />
          </p>
          <p>
            <toggle v-model="user.emailSubscriptions" on-value="marketing" name="email_subscriptions[]" @update:model-value="change">
              {{ $t('Commerciali') }}
            </toggle>
            {{ ' ' }}
            <form-help-tooltip :text="$t('Riceve via mail le notifiche commerciali (es. offerte, preventivi)')" />
          </p>
          <p>
            <toggle v-model="user.emailSubscriptions" on-value="technical" name="email_subscriptions[]" @update:model-value="change">
              {{ $t('Tecniche') }}
            </toggle>
            {{ ' ' }}
            <form-help-tooltip :text="$t('Riceve via mail le notifiche tecniche (es. tickets, notifiche di sistema)')" />
          </p>
        </div>
      </div>

      <pf-button type="submit" variant="primary" :disabled="disabled || !valid">
        {{ submitLabel }}
      </pf-button>
      {{ ' ' }}
      <i><span v-if="!disabled && !valid">{{ $t('Attiva almeno una tipologia di permessi o di notifiche') }}</span></i>
    </fieldset>
  </form>
</template>

<script lang="ts">
import { defineComponent, type PropType } from 'vue';
import cloneDeep from 'lodash-es/cloneDeep';
import Toggle from '../components/toggle.vue';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';

export type FormUserData = {
  is_owner?: boolean;
  emailSubscriptions: string[],
  grants: ('billing' | 'sales' | 'developer' | 'admin')[],
}

export default defineComponent({
  name: 'FormUserAccess',

  components: {
    Toggle,
    FormHelpTooltip,
  },

  props: {
    modelValue: {
      type: Object as PropType<FormUserData>,
      required: true,
    },
    disabled: Boolean,
    submitLabel: String,
  },

  emits: {
    'update:modelValue': (value: FormUserData) => value !== undefined,
    submit: (e: Event, userData: FormUserData) => e !== undefined && userData !== undefined,
  },

  data() {
    return {
      user: cloneDeep(this.modelValue),
    };
  },

  computed: {
    valid() {
      return this.user.emailSubscriptions.length > 0 || this.user.grants.length > 0;
    },

    billingGrant() {
      return this.user.grants.includes('billing');
    },

    salesGrant() {
      return this.user.grants.includes('sales');
    },

    developerGrant() {
      return this.user.grants.includes('developer');
    },
  },

  watch: {
    modelValue: {
      handler() {
        this.user = { ...this.modelValue };
      },
      immediate: true,
    },

    billingGrant(v) {
      if (v) {
        this.user.emailSubscriptions.push('billing');
      }
    },

    salesGrant(v) {
      if (v) {
        this.user.emailSubscriptions.push('marketing');
      }
    },

    developerGrant(v) {
      if (v) {
        this.user.emailSubscriptions.push('technical');
      }
    },
  },

  methods: {
    change() {
      this.$emit('update:modelValue', this.user);
    },

    submit(e: Event) {
      if (!this.disabled) {
        this.$emit('submit', e, this.user);
        this.$emit('update:modelValue', this.user);
      }
    },
  },
});
</script>
