<template>
  <page
    :name="$t('Rinnovo')"
    :title="`Pagamento ${is_recupero ? $t('recupero/conferma ') : $t('rinnovo ')}${
      !products || products.length > 1 ? $t('gruppo') : $t('prodotto')
    }`"
    :state="asyncComputed"
    :empty="!products || !products.length"
  >
    <template #breadcrumbs>
      <router-link v-slot="{ navigate }" custom :to="{ name: 'products' }">
        <li>
          <a @click="navigate">{{ $t('Prodotti') }}</a>
        </li>
      </router-link>
    </template>

    <payment-report
      :result="Number($route.query.res)"
      :error="$pop($route.query.err)"
      :success="
        is_recupero
          ? $t('Recupero/Conferma servizi completato con successo')
          : $t('Rinnovo servizi completato con successo')
      "
      route="products"
      :link="$t('Torna alla lista dei prodotti')"
    >
      <template v-if="products.length == 1">
        <h4 v-for="p in products" :key="p.id">
          {{ p.descrizione }}
          <template v-if="p.qt > 1"> (x{{ p.qt }}) </template>
          <template v-if="products.length > 1">
            - {{ $currency(p.importo, login.currency) }} <small>{{ $t('IVA inclusa') }}</small>
          </template>
        </h4>
      </template>

      <ul v-for="p in products" v-else :key="p.id">
        <li>
          {{ p.descrizione }}
          <template v-if="p.qt > 1"> (x{{ p.qt }}) </template>
          <template v-if="products.length > 1">
            - {{ $currency(p.importo, login.currency) }} <small>{{ $t('IVA inclusa') }}</small>
          </template>
        </li>
      </ul>

      <form v-if="importo_da_pagare > 0" method="post" action="/api/pay_product" @submit="submit">
        <fieldset>
          <div class="form-group">
            <input v-for="id in ids" :key="id" type="hidden" name="ids[]" :value="id">

            <payment-methods
              v-model="method"
              :totale-offerta-ivato="importo_da_pagare"
              :currency="login.currency"
            />
          </div>

          <h3 v-if="importo_da_pagare > 0" class="subtitle">
            {{ $t('Totale: {0}', [formatCurrency(importo_da_pagare, login.currency)])
            }}<small>{{ $t('IVA inclusa') }}</small>
          </h3>

          <pf-button type="submit" variant="primary" :disabled="!method">
            <template v-if="method === 'custom'">{{ $t('Conferma il rinnovo') }}</template>
            <template v-else>{{ $t('Procedi al pagamento') }}</template>
          </pf-button>
          {{ ' ' }}
          <em v-if="method !== 'custom'">{{ $t("Verrai reindirizzato alla pagina di pagamento. L'operazione può durare qualche secondo.") }}</em>
        </fieldset>
      </form>
    </payment-report>
  </page>
</template>

<style scoped>
.form-group.methods label {
  margin-right: 15px;
}
</style>

<script lang="ts">
import { setupAsyncComputed } from '@common/asyncComputed';
import { formatCurrency } from '@common/utils';

import Page from '../components/page.vue';
import PaymentReport from '../components/payment-report.vue';
import PaymentMethods, { type Methods} from '../components/payment-methods.vue';
import { useLoginStore } from '@/store/login';
import { usePaypalStore } from '@/store/paypal';
import { ProductOrdersResource, type ProductOrderDetail } from '@/resources';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ProductRenewPage',

  components: {
    Page,
    PaymentReport,
    PaymentMethods,
  },

  setup() {
    const login = useLoginStore();
    return {
      login,
      ...setupAsyncComputed({
        products: {
          async get(this: any) {
            return (await new ProductOrdersResource().get(this.ids));
          },
          default: [] as ProductOrderDetail[],
        },
      }),
    };
  },

  data() {
    return {
      ids: [] as string[],
      method: undefined as Methods | undefined,
    };
  },

  computed: {
    is_recupero() {
      return this.products?.length && ['scaduto', 'non rinnovare'].includes(this.products[0].stato_amministrativo);
    },

    importo_da_pagare() {
      return this.products?.reduce((sum, product) => sum + product.importo, 0);
    },
  },

  mounted() {
    this.ids = this.$route.params.id.toString().split('-');
  },

  methods: {
    formatCurrency,

    submit(e: Event) {
      if (!this.method) {
        e.preventDefault();
      }

      if (this.method === 'axerve-paypal') {
        const paypal = usePaypalStore();
        paypal.save();
      }
    },
  },
});
</script>
