p<template>
  <ul class="radio-wrapper">
    <li v-for="(v, k) of opzioniLocation" :key="k">
      <label>
        <input
          type="radio"
          :name="name"
          :required="required"
          :value="k"
          :checked="k === modelValue"
          @change="$emit('update:modelValue', k as LocationCode)"
        >
        &nbsp;{{ emojiflag(k.slice(0,2)) }} {{ v }}
      </label>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { type Location, type LocationCode, emojiflag } from '@common/utils';
import { useWizardQuestion } from '../composition';

defineProps<{
  modelValue: LocationCode | undefined;
  name: string;
  required?: boolean;
  opzioniLocation: Partial<Location>;
}>();

defineEmits<{
  (name: 'update:modelValue', v: LocationCode): void;
}>();

useWizardQuestion();
</script>
