<template>
  <p v-if="showPrezzo">
    <wizard-price-tag :product="product" :product-instead="productInstead" />
  </p>
  <p v-if="showUtenti">
    {{ _t('Licenza per {0} utenti', [product.utenti]) }}
  </p>
  <pf-toggle
    :model-value="modelValue"
    :required="required"
    :on-value="true"
    :off-value="false"
    :off-text="$t('No')"
    :on-text="$t('Sì')"
    @update:model-value="$emit('update:modelValue', $event)"
  />
</template>

<script setup lang="ts">
import { $t as _t } from '@/i18n';
import { useWizardQuestion } from '../composition';
import WizardPriceTag from '../wizard-price-tag.vue';
import { default as PfToggle } from '../../toggle.vue';
import type { ProductPriceInfo } from '../wizard.vue';

defineProps<{
  modelValue: boolean;
  required?: boolean;
  showPrezzo?: boolean;
  showUtenti?: boolean;
  product: ProductPriceInfo;
  productInstead?: ProductPriceInfo;
}>();

defineEmits<{
  (name: 'update:modelValue', v: boolean): void;
}>();

useWizardQuestion();
</script>
