<template>
  <page :name="$t('Utenti')" hide-breadcrumbs>
    <pf-modal
      v-if="showLegend"
      show
      :title="$t('Legenda permessi utente')"
      outside-close
      confirm-button=""
      :cancel-button="$t('Chiudi')"
      @cancel="showLegend = false">
      <user-legend />
    </pf-modal>

    <div>
      <div class="panel panel-default">
        <div class="panel-heading">
          <h4>
            <user-icon />
            {{ $t('Invia invito di accesso') }}
          </h4>
        </div>
        <div class="panel-body">
          <p>
            {{ $t("Inserisci un indirizzo email valido e specifica i permessi e le notifiche email che verranno inviate all'utente.") }}
            <pf-button variant="link" @click="showLegend = true">{{ $t('Leggi la legenda dei permessi') }}</pf-button>
          </p>
          <form-user-access
            v-model="invite"
            :disabled="!inviteEmail || sending"
            :submit-label="$t('Invia invito')"
            @submit="sendInvite"
          >
            <div class="row">
              <div class="form-group col-md-6 col-lg-4">
                <label>{{ $t('Email') }}</label>
                <x-input v-model="inviteEmail" type="email" name="email" maxlength="100" />
              </div>
            </div>
          </form-user-access>
        </div>
      </div>

      <h3>{{ $t('Utenti collegati') }}</h3>
      <async-state
        :loading="asyncComputed.users.updating || asyncComputed.invites.updating"
        :empty="!members.length"
        :empty-text="$t('Nessun utente collegato')"
      >
        <pf-list-view :rows="(members as any)" key-name="mail" :items-per-page="0" expandable>
          <template #default="{ row }">
            <pf-list-item>
              <template #left>
                <span class="fa list-view-pf-icon-md">
                  <paper-plane-icon v-if="row.type === 'invite'" />
                  <user-icon v-else />
                </span>
              </template>

              <template #heading>
                <span v-if="!row.firstname && !row.lastname" :title="row.mail">{{ row.mail }}</span>
                <template v-else>
                  <div :title="`${row.firstname} ${row.lastname}`">{{ row.firstname }} {{ row.lastname }}</div>
                  <div :title="row.mail">&lt;{{ row.mail }}&gt;</div>
                </template>
              </template>

              <template #description>
                <div class="user-grants">
                  <span v-if="row.is_self" class="label label-success">{{ $t('Sei tu') }}</span>
                  <span v-if="row.is_owner" class="label label-primary">{{ $t('Proprietario') }}</span>
                  <span v-else-if="row.is_admin" class="label label-warning">{{ $t('Amministratore') }}</span>
                </div>

                <div class="user-notifications">
                  <strong>{{ $t('Notifiche attive') }}: </strong>
                  <template v-if="row.emailSubscriptions.length">
                    {{ ' ' }}
                    <span v-if="row.emailSubscriptions.includes('technical')">{{ $t('tecniche') }}{{ ' ' }}</span>
                    <span v-if="row.emailSubscriptions.includes('marketing')">{{ $t('commerciali') }}{{ ' ' }}</span>
                    <span v-if="row.emailSubscriptions.includes('billing')">{{ $t('amministrative') }}{{ ' ' }}</span>
                  </template>
                  <template v-else>{{ $t('Nessuna') }}</template>
                </div>
              </template>
            </pf-list-item>
          </template>

          <template #action="{ row }">
            <template v-if="row.type == 'invite'">
              <pf-button @click.prevent="resendInvite(row.mail)">
                <paper-plane-icon />
                {{ $t('Reinvia invito') }}
              </pf-button>

              <pf-button
                variant="danger"
                @click.prevent="deleteInvite(row.mail)"
                v-bind="{title: $t('Annulla invito')}"
              >
                <trash-icon />
                <span class="sr-only">{{ $t('Annulla invito') }}</span>
              </pf-button>
            </template>

            <pf-button
              v-else
              :disabled="row.is_owner"
              v-bind="{title: $t('Rimuovi utente')}"
              variant="danger"
              @click.prevent="deleteUser(row)"
            >
              <template v-if="row.is_self"> <power-off-icon /> {{ $t('Lascia') }} </template>
              <template v-else>
                <trash-icon />
                <span class="sr-only">{{ $t('Rimuovi utente') }}</span>
              </template>
            </pf-button>
          </template>

          <template #expansion="{ row }">
            <form-user-access
              :model-value="row"
              :disabled="sending"
              :submit-label="$t('Aggiorna permessi e notifiche')"
              @submit="refreshUser(row)"
              @update:model-value="updateUser(row, $event)"
            />
          </template>
        </pf-list-view>
      </async-state>
    </div>
  </page>
</template>

<style lang="scss" scoped>
.user-grants {
  > .label {
    display: inline-block;
    margin-right: 5px;
    line-height: 11px;

    &:last-child {
      margin-right: 0;
    }
  }
}

.user-notifications{
  margin-top: 5px;

  span {
    text-transform: capitalize;
  }
}
</style>

<script lang="ts">
import { http } from '../http';
import Page from '../components/page.vue';
import AsyncState from '../components/async-state.vue';
import FormUserAccess, { type FormUserData } from '../components/form-user-access.vue';
import XInput from '@/components/x-input.vue';
import { setupAsyncComputed } from '@common/asyncComputed';
import { useLoginStore } from '@/store/login';
import { useAppStore } from '@/store/app';
import { Resource, type GenericResource } from '@/resources';
import UserLegend from '../components/user-legend.vue';
import { defineComponent } from 'vue';
import cloneDeep from 'lodash-es/cloneDeep';
import { $t } from '@/i18n';
import UserIcon from '@vue-patternfly/icons/user-icon';
import TrashIcon from '@vue-patternfly/icons/trash-icon';
import PowerOffIcon from '@vue-patternfly/icons/power-off-icon';
import PaperPlaneIcon from '@vue-patternfly/icons/paper-plane-icon';

const inviteDataReset: FormUserData = {
  emailSubscriptions: [],
  grants: [],
};

interface GrantMember {
  id?: number;
  type: string;
  mail: string;
  firstname: string;
  lastname: string;
  is_owner: boolean;
  is_admin: boolean;
  is_self: boolean;
  grants: string[],
  emailSubscriptions: string[],
}

export default defineComponent({
  name: 'UsersPage',

  components: {
    Page,
    AsyncState,
    XInput,
    FormUserAccess,
    UserLegend,
    UserIcon,
    TrashIcon,
    PowerOffIcon,
    PaperPlaneIcon,
  },

  setup() {
    const login = useLoginStore();
    return {
      login,
      ...setupAsyncComputed({
        users: {
          get(this: any): Promise<GenericResource[]> {
            return new Resource('users').get();
          },
          default: [] as GenericResource[],
        },
        invites: {
          get(this: any): Promise<GenericResource[]> {
            return new Resource('invites').get();
          },
          default: [] as GenericResource[],
        },
      }),
    };
  },

  data(this: void) {
    return {
      invite: cloneDeep(inviteDataReset),
      inviteEmail: '',
      sending: false,
      showLegend: false,
    };
  },

  computed: {
    members() {
      const members: GrantMember[] = [];
      for (const user of this.users) {
        members.push({
          id: user.id,
          type: 'user',
          mail: user.mail,
          firstname: user.firstname,
          lastname: user.lastname,
          is_owner: user.grants.includes('owner'),
          is_admin: user.grants.includes('admin'),
          is_self: user.username === this.login.username,
          grants: user.grants,
          emailSubscriptions: user.email_subscriptions,
        });
      }
      for (const invite of this.invites) {
        members.push({
          type: 'invite',
          mail: invite.email,
          firstname: invite.firstname,
          lastname: invite.lastname,
          is_owner: false,
          is_admin: invite.grants.includes('admin'),
          is_self: false,
          grants: invite.grants,
          emailSubscriptions: invite.email_subscriptions,
        });
      }
      return members;
    },
  },

  methods: {
    async sendInvite(e: Event) {
      if (!(e.target instanceof HTMLFormElement)) {
        return;
      }

      const data = new FormData(e.target);

      if (this.sending || !window.confirm($t("Inviare l'invito a {0}?", [data.get('email')]))) {
        return;
      }

      this.sending = true;
      try {
        await new Resource('invites').post(data);
      } finally {
        this.sending = false;
      }

      this.invite = cloneDeep(inviteDataReset);
      this.inviteEmail = '';

      this.asyncComputed.invites.update();
    },

    async resendInvite(email: string) {
      if (this.sending || !window.confirm($t("Reinviare l'invito a {0}?", [email]))) {
        return;
      }

      this.sending = true;
      try {
        await new Resource('invites').post({
          action: 'resend',
          email,
        });
      } finally {
        this.sending = false;
      }
    },

    async deleteInvite(email: string) {
      if (this.sending || !window.confirm($t("Annullare l'invito a {0}?", [email]))) {
        return;
      }

      this.sending = true;
      try {
        await new Resource('invites').delete(email);
        this.asyncComputed.invites.update();
      } finally {
        this.sending = false;
      }
    },

    updateUser(row: GrantMember, userData: FormUserData) {
      let target: GenericResource[];
      let index: number;
      if (row.type === 'user') {
        target = this.users;
        index = target.findIndex(user => user.id === row.id);
      } else {
        target = this.invites;
        index = target.findIndex(user => user.email === row.mail);
      }
      if (index === -1) {
        return;
      }
      target[index] = {
        ...target[index],
        grants: userData.grants,
        email_subscriptions: userData.emailSubscriptions,
      };
    },

    async refreshUser(row: GrantMember) {
      if (this.sending) {
        return;
      }

      const res = row.type === 'invite' ? 'invites' : 'users';
      const id = res === 'invites' ? row.mail : row.id;

      if (!id) {
        throw new Error('Missing member id/email');
      }

      this.sending = true;
      try {
        await new Resource(res).put(id, {
          grants: row.grants,
          email_subscriptions: row.emailSubscriptions,
        });
      } finally {
        this.sending = false;
      }

      this.asyncComputed[res].update();
    },

    async deleteUser(row: GrantMember) {
      if (this.sending || !row.id || (this.login.grants.owner && row.id == this.login.id)) {
        return;
      }

      const res = row.type === 'invite' ? 'invites' : 'users';

      if (res === 'users' && !window.confirm($t('Rimuovere questa utenza?'))) {
        return;
      }

      this.sending = true;
      try {
        await new Resource(res).delete(row.id);
      } finally {
        this.sending = false;
      }

      if (res === 'users' && row.id == this.login.id) {
        await http.post('/api/auth/logout');

        const app = useAppStore();
        app.logout();

        this.$router.push({
          name: 'login',
          query: {
            ref: this.$route.fullPath,
          },
        });
      } else {
        this.asyncComputed[res].update();
      }
    },
  },
});
</script>
