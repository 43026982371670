<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :title="$t('Servizi aggiuntivi')">
    <div v-if="wizard.path === 'altro'" class="question-wrapper">
      <h3>{{ $t('Vuoi attivare una licenza terminal server?') }}</h3>
      <wizard-question-prodotto
        v-model="wizard.addonTerminalServer"
        :product="wizard.getProdotto(wizard.codiciProdottiAddon.terminalServer)"
        show-prezzo
      />
    </div>

    <div v-if="wizard.path === 'altro'" class="question-wrapper">
      <h3>{{ $t('Vuoi attivare un il servizio Atlas di archiviazione delle mail?') }}</h3>
      <wizard-question-prodotto
        v-model="wizard.addonAtlas"
        :product="wizard.getProdotto(wizard.codiciProdottiAddon.atlas)"
        show-prezzo
      />
    </div>

    <div class="question-wrapper">
      <h3>{{ $t('Vuoi attivare un certificato SSL?') }}</h3>
      <p>
        {{ $t("Tutti i nostri servizi comprendono l'attivazione di un certificato SSL \"Let's Encrypt\" gratuito per attivare la navigazione cifrata in HTTPS. Oppure puoi scegliere un certificato standard a pagamento con garanzia assicurativa inclusa. Oppure attivare un certificato EV che ti permetterà di rendere più evidente all'interno dei browser questo grado di sicurezza oltre ad avere l'assicurazione in caso di violazione dell'algoritmo. Un certificato EV è particolarmente indicato per tutti i siti di Ecommerce.") }}
      </p>
      <label>
        <input v-model="wizard.codiceSsl" type="radio" name="codiceSsl" value>
        {{ $t("Certificato Let's Encrypt incluso e gratuito") }}
      </label>
      <wizard-question-prodotti
        v-model="wizard.codiceSsl"
        :prodotti="wizard.codiciProdottiAddon.ssl"
        name="codiceSsl"
        show-feature
        required
      />
    </div>
  </wizard-form>
</template>

<style scoped>
:deep(.features .list-view-pf-additional-info-item) {
  width: 100%;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { ouiaProps, useOUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';

import WizardQuestionProdotti from '../questions/wizard-question-prodotti.vue';
import WizardQuestionProdotto from '../questions/wizard-question-prodotto.vue';
import WizardForm from '../wizard-form.vue';

export default defineComponent({
  name: 'WizardStepSsl',

  components: {
    WizardQuestionProdotti,
    WizardQuestionProdotto,
    WizardForm,
  },

  props: {
    ...ouiaProps,
  },

  setup(props) {
    return {
      ouiaProps: useOUIAProps(props),
      ...useWizardStep({
        isEnabled: wizard => wizard.askSsl,
      }),
    };
  },

  computed: {
    atLeastOneAddon() {
      return this.wizard.path === 'altro' && !(this.wizard.addonTerminalServer || this.wizard.addonWebAccelerator || this.wizard.codiceSsl);
    },
  },
});
</script>
