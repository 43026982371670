<template>
  <page
    v-model:filter="q"
    :name="$t('I miei clienti')"
    :description="$t('Accedi al pannello dei clienti o richiedi l\'accesso')"
    :state="asyncComputed"
    :loading="asyncComputed.customers.updating"
    with-filter
  >
    <h3>{{ $t('Clienti assegnati') }}</h3>
    <div v-if="directCustomers.length" class="customer-table">
      <customers-list :customers="directCustomers" :filter="q" />
    </div>
    <div v-else class="blank-slate-pf">
      <p>{{ $t('Nessun cliente è associato alla tua azienda') }}</p>
    </div>

    <h3>{{ $t('Clienti presentati') }}</h3>
    <div v-if="presentedCustomers.length" class="customer-table">
      <customers-list :customers="presentedCustomers" :filter="q" />
    </div>
    <div v-else class="blank-slate-pf">
      <p>{{ $t('Nessun cliente è associato alla tua azienda') }}</p>
    </div>

    <div v-if="affiliationLink" class="panel panel-default panel-affiliate-link">
      <div class="panel-heading">{{ $t('Link di affiliazione') }}</div>
      <div class="panel-body">
        <p>{{ $t("Utilizzando questo url in fase di registrazione l'utente verrà aggiunto automaticamente alla vostra lista clienti.") }}</p>

        <pf-input-group>
          <input
            ref="linkInput"
            v-model="affiliationLink"
            class="form-control"
            readonly
            @click="linkInput?.select()"
          >
          <div class="input-group-btn">
            <pf-button @click="copyLink">{{ $t('Copia Link') }}</pf-button>
          </div>
        </pf-input-group>
        <h3>{{ $t('Artera partner badge') }}</h3>
        <p>{{ $t('Incolla uno di questi codici sul tuo sito per mostrare il badge "Partner Artera" con il tuo link di affiliazione') }}</p>
        <div class="row">
          <div class="col-md-4 ">
            <h4>{{ $t('Versione orizzontale')}} <pf-button class="btn btn-default" small @click="copyToClipBoard(horizontalBadge)">{{ $t('Copia codice') }}</pf-button></h4>
            <pre>{{ horizontalBadge }}</pre>
          </div>
          <div class="col-md-4 partner-badge-preview">
            <a :href="affiliationLink">
              <img src="https://resources.artera.net/brand/artera-partnerbadge-horizontal.svg" width="300">
            </a>
          </div>
        </div>
        <div class="row">
          <div class="col-md-4 ">
            <h4>{{ $t('Versione verticale')}} <pf-button class="btn btn-default" small @click="copyToClipBoard(verticalBadge)">{{ $t('Copia codice') }}</pf-button></h4>
            <pre>{{ verticalBadge }}</pre>
          </div>
          <div class="col-md-4 partner-badge-preview">
            <a :href="affiliationLink">
              <img src="https://resources.artera.net/brand/artera-partnerbadge-vertical.svg" width="100">
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="panel panel-default">
      <div class="panel-heading">{{ $t("Crea un'azienda prospect") }}</div>
      <div class="panel-body">
        <form-prospect :key="formKey" @submit="newProspect" />
      </div>
    </div>
  </page>
</template>

<style lang="scss" scoped>
.panel-affiliate-link input {
  background-color: inherit;
  color: inherit;
}

.customer-table {
  max-height: 500px;
  overflow: scroll;
}

.partner-badge-preview {
  margin-top: 50px;
}
</style>

<script lang="ts">
import { http } from '../http';
import { setupAsyncComputed } from '@common/asyncComputed';
import Page from '../components/page.vue';
import FormProspect from '../components/form-prospect.vue';
import CustomersList from '../components/customers-list.vue';
import { CompanyAccessResource, MyCustomersResource } from '@/resources';
import { defineComponent, type Ref, ref } from 'vue';
import { copyToClipBoard } from "@common/utils";

export default defineComponent({
  name: 'MyCustomersPage',

  components: {
    Page,
    FormProspect,
    CustomersList,
  },

  setup() {
    const linkInput: Ref<HTMLInputElement | undefined> = ref();

    return {
      linkInput,
      ...setupAsyncComputed({
        customers: {
          async get() {
            const customers = await new MyCustomersResource().get();
            const loginaziende = await new CompanyAccessResource().get();
            return customers.map(c => ({ ...c, login: loginaziende[c.id] }));
          },
          default: [],
        },

        affiliationLink: {
          async get() {
            return (await http.get('/rest/my_customers/affiliationlink')).data;
          },
          default: null,
        },
      }),
    };
  },
  data() {
    return {
      q: '', // search query
      formKey: 0, // utilizzata per effettuare il reset del form
    };
  },

  computed: {
    directCustomers() {
      return this.customers.filter((c) => !c.presented);
    },

    presentedCustomers() {
      return this.customers.filter((c) => c.presented);
    },

    horizontalBadge() {
      return `<a href="${this.affiliationLink}" target="_blank">\n  <img src="https://resources.artera.net/brand/artera-partnerbadge-horizontal.svg" width="300">\n</a>`
    },

    verticalBadge() {
      return `<a href="${this.affiliationLink}" target="_blank">\n  <img src="https://resources.artera.net/brand/artera-partnerbadge-vertical.svg" width="100">\n</a>`
    },
  },

  methods: {
    copyLink() {
      if (!this.affiliationLink || !this.linkInput) {
        return;
      }
      const selection = document.getSelection();
      const selected = selection && selection.rangeCount > 0 ? selection.getRangeAt(0) : false;
      this.linkInput.select();
      document.execCommand('copy');
      if (selection && selected) {
        selection.removeAllRanges();
        selection.addRange(selected);
      }
    },

    newProspect() {
      this.asyncComputed.customers.update();
      this.formKey += 1;
    },

    copyToClipBoard,
  },
});
</script>
