<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :title="$t('Email')">
    <div class="question-wrapper">
      <p v-if="wizard.path === 'cloud'">
        {{
          $t(
            "L'attivazione di un cloud dedicato non comprende il servizio email, se non viene attivata anche una licenza cPanel o Plesk.",
          )
        }}
      </p>
      <p v-if="wizard.path === 'cloud'">
        {{
          $t(
            'In questo caso è possibile attivare il servizio email aggiuntivo su hosting condiviso configurato appositamente per la gestione della posta elettronica.',
          )
        }}
      </p>

      <div v-if="wizard.path !== 'mail'">
        <label>
          <input v-model="wizard.codiceProdottoMail" type="radio" name="codiceProdottoMail" value>
          {{ $t('Non desidero attivare il servizio email') }}
        </label>
      </div>
      <div class="product-wrapper">
        <div>
          <wizard-question-prodotti
            v-model="wizard.codiceProdottoMail"
            :prodotti="wizard.codiciProdottiMail"
            name="codiceProdottoMail"
            :required="wizard.path === 'mail'"
            show-feature
            variant="table"
          />
        </div>
      </div>
    </div>
  </wizard-form>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { ouiaProps, useOUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';

import WizardQuestionProdotti from '../questions/wizard-question-prodotti.vue';
import WizardForm from '../wizard-form.vue';

export default defineComponent({
  name: 'WizardStepMail',

  components: {
    WizardQuestionProdotti,
    WizardForm,
  },

  props: {
    ...ouiaProps,
  },

  setup(props) {
    return {
      ouiaProps: useOUIAProps(props),
      ...useWizardStep({
        isEnabled: wizard => wizard.askMail,
      }),
    };
  },
});
</script>
