<template>
  <span v-if="feature === 'spazio'" :class="{highlight: product.categoria === 'Cloud Shared Hosting'}">
    <template v-if="product.spazio === 0"> {{ $t('All-flash Storage illimitato') }}</template>
    <template v-else-if="product.spazio"> {{ $size(product.spazio*1000000) }} {{ $t('All-flash Storage') }}</template>
  </span>

  <template v-else-if="feature === 'traffico'">
    <span v-if="product.traffico === 0"> {{ $t('Traffico illimitato') }}</span>
    <span v-else-if="product.traffico"> <template v-if="showLabel">{{ $t('Traffico') }}:</template> {{ $size(product.traffico*1000000) }}</span>
  </template>

  <span v-else-if="feature === 'database' && product.database">
    <span><template v-if="showLabel">{{ $t('Database') }}{{ ' ' }}</template>{{ $t('illimitati') }}</span>
  </span>

  <span v-else-if="feature === 'inode' && product.inode">
    <span><template v-if="showLabel">{{ $t('Inode') }}: </template>{{ $t('illimitati') }}</span>
  </span>

  <span v-else-if="feature === 'nr_domini'">
    {{ $t('Multidominio') }}:
    <span v-if="product.nr_domini === 0">{{ $t('No') }}</span>
    <span v-else class="capitalized">{{ $t('{0} domini', [product.nr_domini]) }}</span>
  </span>

  <template v-else-if="feature === 'ip_dedicato' && product.ip_dedicato">
    <span><template v-if="showLabel">{{ $t('IP Dedicato') }}: </template>{{ product.ip_dedicato }}</span>
  </template>

  <template v-else-if="feature === 'monitoring' && product.monitoring">
    <span><template v-if="showLabel">{{ $t('Monitoring') }}: </template>{{ monitoring[product.monitoring] }}</span>
  </template>

  <with-var v-else :value="allFeatures[feature]" #="{ var: featDef }">
    <span
      :title="featDef.label"
      :class="{capitalized: featDef.capitalized}"
    >
      <template v-if="featDef.showLabel && showLabel">{{ featDef.label }}:{{ ' ' }}</template>

      <template v-if="typeof product[feature] === 'boolean'">
        <template v-if="showLabel && product[feature]">{{ featDef.label }}{{ ' ' }}</template>
        <template v-else>{{ product[feature] ? '✅' : '❌' }} </template>
      </template>
      <template v-else-if="product[feature]">
        {{ product[feature] }} <template v-if="featDef.um">{{ featDef.um }}</template>
      </template>
      <template v-else>❌</template>

      <form-help-tooltip v-if="showLabel && featDef.tooltip" :text="featDef.tooltip" />
      <template v-if="featDef.description">{{ featDef.description }}</template>
    </span>
  </with-var>
</template>

<style lang="scss" scoped>
span {
  white-space: pre-wrap;
  text-align: left;
  margin-left: 2px;
}

strong {
  font-size: inherit;
}

.pficon, .fa {
  margin-right: 5px;
  width: 20px;
}

:deep(svg) {
  margin: 2px;
}

.capitalized {
  text-transform: capitalize;
}

.highlight {
  font-weight: bold;
  color: #ec7a08;
}
</style>

<script lang="ts">
import CpuIcon from '@vue-patternfly/icons/cpu-icon';
import MemoryIcon from '@vue-patternfly/icons/memory-icon';
import VolumeIcon from '@vue-patternfly/icons/volume-icon';
import TrendUpIcon from '@vue-patternfly/icons/trend-up-icon';
import ApplicationsIcon from '@vue-patternfly/icons/applications-icon';
import VirtualMachineIcon from '@vue-patternfly/icons/virtual-machine-icon';
import EnvelopeIcon from '@vue-patternfly/icons/envelope-icon';
import GlobeIcon from '@vue-patternfly/icons/globe-icon';
import DatabaseIcon from '@vue-patternfly/icons/database-icon';
import NetworkIcon from '@vue-patternfly/icons/network-icon';
import ArrowsRotateIcon from '@vue-patternfly/icons/arrows-rotate-icon';
import AddressCardIcon from '@vue-patternfly/icons/address-card-icon';
import NetworkWiredIcon from '@vue-patternfly/icons/network-wired-icon';
import ArrowIcon from '@vue-patternfly/icons/arrow-icon';
import LockIcon from '@vue-patternfly/icons/lock-icon';
import GaugeIcon from '@vue-patternfly/icons/gauge-icon';
import FileIcon from '@vue-patternfly/icons/file-icon';
import EyeIcon from '@vue-patternfly/icons/eye-icon';
import UsersIcon from '@vue-patternfly/icons/users-icon';

export type Feature = {
  [K in keyof ProductFeatures]: {
    label: string,
    icon: any,  //TODO togliere any e mettere "componente" ?
    um?: string,
    description?: string,
    capitalized?: boolean,
    tooltip?: string,
    showLabel?: boolean,
  };
};

export const allFeatures: Feature = {
  spazio: {
    label: _t('Storage'),
    icon: VolumeIcon,
    um: 'GB All-flash Storage',
  },
  traffico: {
    label: _t('Traffico Mensile'),
    icon: TrendUpIcon,
    um: 'GB Traffico',
  },
  core: {
    label: _t('Core'),
    icon: CpuIcon,
    um: 'Core',
  },
  ram: {
    label: _t('RAM'),
    icon: MemoryIcon,
    um: 'GB',
    showLabel: true,
  },
  sistema_operativo: {
    label: _t('Sistema operativo'),
    icon: ApplicationsIcon,
    showLabel: true,
  },
  pannello: {
    label: _t('Pannello di controllo'),
    icon: VirtualMachineIcon,
  },
  servizio_email: {
    label: _t('Caselle email illimitate'),
    icon: EnvelopeIcon,
  },
  registrazione_dominio: {
    label: _t('Registrazione dominio'),
    icon: GlobeIcon,
  },
  database: {
    label: _t('Database'),
    icon: DatabaseIcon,
  },
  gestione_dns: {
    label: _t('Gestione DNS'),
    icon: NetworkIcon,
  },
  backup: {
    label: _t('Backup in location separata'),
    icon: ArrowsRotateIcon,
    tooltip: _t('Rotazione: 3 giornalieri, 2 settimanali, 4 mensili'),
  },
  support: {
    label: _t('Supporto'),
    icon: AddressCardIcon,
    tooltip: _t('La nostra centrale operativa, attiva ininterrottamente 24 ore su 24, 365 giorni all\'anno, monitora costantemente le sonde. In caso di problemi, interverranno i nostri sistemisti di secondo livello, i quali risolveranno qualsiasi inconveniente in completa autonomia. Non sarà necessario neanche l\'apertura di un ticket da parte vostra.'),
    capitalized: true,
    showLabel: true,
  },
  nr_domini: {
    label: _t('Multidominio'),
    icon: NetworkWiredIcon,
  },
  utenti: {
    label: _t('Utenti'),
    icon: UsersIcon,
    showLabel: true,
  },
  monitoring: {
    label: _t('Monitoraggio'),
    icon: EyeIcon,
  },
  ip_dedicato: {
    label: _t('Ip dedicato'),
    icon: ArrowIcon,
  },
  redirect: {
    label: _t('Redirect'),
    icon: ArrowIcon,
  },
  ssl_certificate: {
    label: _t('Certificato SSL gratuito'),
    icon: LockIcon,
  },
  ssh_access: {
    label: _t('Accesso SSH'),
    icon: LockIcon,
  },
  imunify360: {
    label: _t('Licenza Imunify360'),
    icon: LockIcon,
  },
  ddos_protection: {
    label: _t('Protezione DDOS'),
    icon: LockIcon,
  },
  waf: {
    label: _t('Web Application Firewall'),
    icon: LockIcon,
  },
  litespeed: {
    label: _t('Licenza Litespeed'),
    icon: GaugeIcon,
  },
  ttfb: {
    label: 'TTFB',
    icon: GaugeIcon,
    showLabel: true,
  },
  io: {
    label: 'IO',
    icon: GaugeIcon,
    um: 'MB/s',
    showLabel: true,
  },
  iops: {
    label: 'IOPS',
    icon: GaugeIcon,
    showLabel: true,
  },
  ep: {
    label: 'EP',
    icon: GaugeIcon,
    showLabel: true,
  },
  uptime: {
    label: 'Uptime',
    icon: GaugeIcon,
    um: '%',
    showLabel: true,
  },
  inode: {
    label: 'Inode',
    icon: GaugeIcon,
  },
  descrizione: {
    label: _t('Descrizione'),
    icon: FileIcon,
  },
};
</script>

<script lang="ts" setup>
import { $t as _t } from '@/i18n';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import type { CatalogProduct, ProductFeatures } from '@/resources';
import { PRODOTTIANAGRAFICA_MONITORING } from '../common/consts';

defineProps<{
  feature: keyof ProductFeatures
  product: CatalogProduct;
  showLabel?: boolean;
}>();

type Monitoring = {
  [K in typeof PRODOTTIANAGRAFICA_MONITORING[number]]: string;
}

const monitoring: Monitoring = {
  'Solo ping': _t('Solo ping'),
  'Completo': _t('Completo'),
  'Internet Service Provider': _t('Internet Service Provider'),
};
</script>
