<template>
  <wizard-form v-bind="ouiaProps" :hidden="hidden" :title="$t('Nome a dominio')">
    <template v-if="!wizard.searchedDomain || !wizard.tldRegistrabile(wizard.searchedDomain)">
      <div>
        <h3>{{ $t('Qual è il nome a dominio del progetto?') }}</h3>

        <i v-if="wizard.path !== 'dominio'">{{ $t("Se il dominio non è ancora definitivo indicalo comunque. Ci servirà come nome provvisorio del progetto nell'offerta") }}</i>

        <wizard-question-nome-dominio
          v-model="wizard.nomeDominio"
          name="nome_dominio"
          :show-prezzo="wizard.path === 'dominio'"
          required
        />

        <p v-if="wizard.nomeDominio && !wizard.dominioRegistrabile">
          <i>{{ $t("L'estensione del dominio non è fra quelle disponibili per la registrazione.") }}</i>
          <span v-if="wizard.path != 'dominio'">
            <br>
            <i>{{ $t('Puoi procedere comunque con il preventivo, potremo gestire questo aspetto in un secondo momento.') }}</i>
            <br><br>
            <i18n-t
              keypath="Puoi richiedere maggiori informazioni sulle estensioni disponibili {0}."
              tag="span"
            >
              <router-link :to="{ name: 'tickets' }">
                {{ $t('aprendo un ticket') }}
              </router-link>
            </i18n-t>
          </span>
        </p>
      </div>

      <div v-if="wizard.askRegistrazione && wizard.nomeDominio && wizard.dominioRegistrabile" class="question-wrapper">
        <h3>{{ $t('Vuoi registrare o trasferire il dominio?') }}</h3>
        <wizard-question-configurazione-dominio
          v-model="wizard.domainAction"
        />
      </div>
    </template>

    <p v-else-if="wizard.availableDomain !== null">
      {{ $t('Il dominio {0} risulta: ', [wizard.nomeDominio]) }}
      <span v-if="wizard.availableDomain"><strong class="text-success">{{ $t("Disponibile") }}</strong>. {{ $t('Ci occuperemo noi delle pratiche di registrazione.') }}</span>
      <span v-else><strong class="text-warning">{{ $t("Già registrato") }}</strong>. {{ $t('Ci occorrerà solo il codice auth-code e poi ci occuperemo noi di tutte le pratiche di trasferimento.') }}</span>
    </p>

    <div v-if="wizard.registraDominio && wizard.dominioRegistrabile" class="question-wrapper">
      <h3>{{ $t('Vuoi attivare la Registrazione Premium?') }}</h3>
      <p>{{ $t('La registrazione premium è un servizio unico nel suo genere: tramite l’attivazione di questo speciale contratto premium sarà possibile delegare a noi qualsiasi aspetto della vostra registrazione: tecnico, legale, notarile e amministrativo.') }}</p>
      <wizard-question-prodotto
        v-model="wizard.registrazionePremium"
        :product="wizard.getProdotto(wizard.codiciProdottiAddon.registrazionePremium)"
        show-prezzo
      />
    </div>
  </wizard-form>
</template>

<script lang="ts" setup>
import { useOUIAProps, type OUIAProps } from '@common/ouia';
import { useWizardStep } from '../composition';

import WizardQuestionNomeDominio from '../questions/wizard-question-nome-dominio.vue';
import WizardQuestionConfigurazioneDominio from '../questions/wizard-question-configurazione-dominio.vue';
import WizardQuestionProdotto from '../questions/wizard-question-prodotto.vue';
import WizardForm from '../wizard-form.vue';

defineOptions({
  name: 'WizardStepDominio',
});

const props = defineProps<OUIAProps>();
const ouiaProps = useOUIAProps(props);

const { wizard, hidden, isEnabled } = useWizardStep({
  isEnabled: wizard => wizard.askDomain,
});

defineExpose({
  isEnabled,
});
</script>
