<template>
  <login-layout>
    <template v-if="$route.query.welcome" #header>
      <div class="alert alert-success" role="alert">
        <strong>{{ $t('Benvenuto!') }}</strong>
        {{ ' ' }}
        <template v-if="$route.query.welcome == '1'">
          {{ $t("La tua registrazione è stata completata con successo. Riceverai a breve un'email con le istruzioni per accedere.") }}
        </template>
        <template v-else>
          {{ $t("La tua registrazione è stata completata con successo.") }}
        </template>
      </div>
    </template>

    <header ref="scriptInjectElement" class="login-pf-header">
      <h1>{{ $t('Accedi alla tua Area Riservata') }}</h1>
    </header>

    <p v-if="invite.azienda">
      <i18n-t keypath="Invito ad accedere all'area riservata di {0}">
        <strong>{{ invite.azienda }}</strong>
      </i18n-t>
    </p>

    <form @submit.prevent="submit">
      <template v-if="username && password && requireTwoFA">
        <input v-if="!invite.azienda" type="hidden" name="username" :value="username">
        <input v-else type="hidden" name="username" :value="invite.username ? invite.username : invite.email">
        <input type="hidden" name="password" :value="password">

        <div class="form-group">
          <label class="sr-only" for="logintwofa">{{ $t('Codice Authenticator') }}</label>
          <x-input
            id="logintwofa"
            v-model="codeTwoFA"
            name="totp"
            type="text"
            :placeholder="$t('Codice Authenticator')"
            required
            :disabled="sending"
          />
        </div>
      </template>

      <template v-else>
        <div class="form-group">
          <label class="sr-only" for="loginUsername">{{ $t('Username') }}</label>
          <x-input
            v-if="!invite.azienda"
            id="loginUsername"
            v-model="username"
            name="username"
            type="text"
            :placeholder="$t('Username')"
            required
            :disabled="sending"
            autocomplete="username"
          />
          <x-input
            v-else-if="invite.username"
            v-model="invite.username"
            name="username"
            type="text"
            :placeholder="$t('Username')"
            required
            :disabled="sending"
            readonly
          />
          <x-input
            v-else
            v-model="invite.email"
            name="username"
            type="text"
            :placeholder="$t('Username')"
            required
            :disabled="sending"
            readonly
          />

        </div>

        <div class="form-group">
          <label class="sr-only" for="loginPassword">{{ $t('Password') }}</label>
          <x-input
            id="loginPassword"
            v-model="password"
            name="password"
            type="password"
            :placeholder="$t('Password')"
            required
            :disabled="sending"
            autocomplete="current-password"
          />
        </div>

        <div class="form-group login-pf-settings">
          <label class="checkbox-label">
            <input type="checkbox" name="rememberme" :disabled="sending">
            {{ $t('Ricordami per 30 giorni') }}
          </label>
          <router-link :to="{ name: 'resetpassword' }">{{ $t('Password dimenticata?') }}</router-link>
        </div>
      </template>

      <div class="login-buttons">
        <pf-button type="submit" variant="primary" large block :disabled="sending || undefined">
          {{ $t('Log In') }}
          <div v-if="sending" class="spinner spinner-xs" />
        </pf-button>

        <div class="separator">
          <span>{{ $t('oppure') }}</span>
        </div>

        <div ref="googleLoginButton" />

        <a v-if="linkedInLoginUrl" :href="linkedInLoginUrl" class="btn-linkedin btn-block btn-lg" :class="{ disabled: sending }" @click="sending ? $event.preventDefault() : null">
          <img src="@/assets/linkedin-in.png" width="30" height="30" alt="LinkedIn" style="margin-right: 4px">
          <span>
            {{ $t('Accedi con LinkedIn') }}
          </span>
        </a>
      </div>
    </form>

    <p v-if="!invite.username" class="login-pf-signup">
      {{ $t('Non hai ancora un account?') }}
      <router-link :to="{ name: 'signup' }">{{ $t('Registrati') }}</router-link>
    </p>
  </login-layout>
</template>

<script lang="ts">
import { http } from '../../http';
import { setupAsyncComputed } from '@common/asyncComputed';
import { injectScript, pop, sleep } from '@common/utils';
import { loginDestination } from '../../utils';
import LoginLayout from '../../components/login-layout.vue';
import XInput from '@/components/x-input.vue';
import { useAppStore } from '@/store/app';
import { Resource } from '@/resources';
import { defineComponent, ref, type Ref } from 'vue';
import type { Invite } from '@/typings/resources';
import axios from 'axios';
import { useOauthUrls } from './use';
import { $t } from '@/i18n';

export default defineComponent({
  name: 'LoginPage',

  components: {
    LoginLayout,
    XInput,
  },

  setup() {
    const googleLoginButton: Ref<HTMLDivElement | null> = ref(null);
    const scriptInjectElement: Ref<HTMLDivElement | null> = ref(null);

    return {
      ...useOauthUrls(),
      scriptInjectElement,
      googleLoginButton,
      ...setupAsyncComputed({
        invite: {
          async get(this: any): Promise<Invite> {
            if (typeof this.$route.query.antk == 'undefined') {
              return {};
            }

            const invite: Invite = await new Resource('invites').get(this.$route.query.antk);

            if (invite.email && !invite.username) {
              this.$router.push({
                name: 'signup',
                query: this.$route.query,
              });
            }

            return invite;
          },
          default: {} as Invite,
        },
      }),
    };
  },

  data() {
    return {
      sending: false,
      requireTwoFA: false,
      username: '',
      password: '',
      codeTwoFA: '',
      showMessage: true,
    };
  },

  async mounted() {
    const app = useAppStore();

    const qRef = pop(this.$route.query.ref);
    if (qRef) {
      localStorage.setItem('ref', qRef);
    }

    const msg = pop(this.$route.query.msg);
    if (msg) {
      app.toast(msg, pop(this.$route.query.msglvl) ?? 'info');
    }

    // try to use the provided token to login
    const username = pop(this.$route.query.username);
    const token = pop(this.$route.query.token);

    if (username && token) {
      this.sending = true;

      try {
        const data = new FormData();
        data.append('username', username);
        data.append('token', token);
        const referer = localStorage.getItem('ref');
        if (referer) {
          data.append('referer', referer);
        }

        await sleep(200); // php responds before its session is flushed to the storage. give it some time.
        const response = await http.post('/api/auth/loginwithtoken', data);

        if (response.data.result === 'ok') {
          app.toast($t('Login effettuato con successo.'), 'success');
          this.$router.push(loginDestination({
            ref: response.data.referer,
            ...this.$route.query,
          }));
        } else {
          this.$router.push({
            name: 'login',
            query: {
              ...this.$route.query,
              token: null,
            },
          });
        }
      } finally {
        this.sending = false;
      }
    } else  {
      this.initGoogleClient();
    }
  },

  methods: {
    async initGoogleClient() {
      if (typeof import.meta.env.GOOGLE_CLIENT_ID !== 'string' || !this.scriptInjectElement) {
        return;
      }

      try {
        await injectScript('https://accounts.google.com/gsi/client', this.scriptInjectElement);
      } catch (e) {
        // Failed to load
        return;
      }

      const google: typeof import('google-one-tap') | undefined = (window as any).google;
      if (!google) {
        return;
      }

      google.accounts.id.initialize({
        client_id: import.meta.env.GOOGLE_CLIENT_ID,
        callback: this.submit,
      });

      if (this.googleLoginButton) {
        google.accounts.id.renderButton(this.googleLoginButton, { theme: "outline", size: "large" });
      }

      google.accounts.id.prompt();
    },

    async submit(e: Event | {credential?: string}) {
      let data;
      if (e instanceof Event) {
        if (!(e.target instanceof HTMLFormElement)) {
          return;
        }
        data = new FormData(e.target);
      } else {
        if (!e.credential) {
          return;
        }
        data = new FormData();
        data.append('google_token', e.credential);
      }

      this.sending = true;

      try {
        data.append('check', await this.fetchToken());
        const result = (await http.post('/api/auth/login', data)).data.result;

        if (result == 'totp') {
          this.requireTwoFA = true;
        } else if (result == 'ok') {
          this.$router.push(loginDestination(this.$route.query));
        }
      } catch (ex: any) {
        if (ex.response && axios.isAxiosError(ex) && ex.response.status === 401) {
          const app = useAppStore();
          app.toast((ex.response.data as any).description, 'danger');
        }
      } finally {
        this.sending = false;
      }
    },

    async fetchToken() {
      return (await http.post('/api/auth/token')).data.token;
    },
  },
});
</script>
