<template>
  <x-form
    xhr
    encoding="json"
    action="/rest/my_customers"
    :submit-text="$t('Invia')"
    :submit-help="`* ${$t('Campo obbligatorio')}`"
    @submit="onSubmit"
  >
    <p>
      {{ $t(
        'Tramite questo form potrai inserire un nuovo prospect nel nostro sistema e generare offerte a lui intestate',
      ) }}
    </p>

    <billing-form embed-for="prospect" :modal="modal" />

    <h3>{{ $t('Contatto') }}</h3>
    <div class="row">
      <div class="form-group col-md-6" :class="{'col-lg-4': !modal}">
        <label for="prospectFirstname">{{ $t('Nome Contatto') }} *</label>
        <x-input
          id="prospectFirstname"
          v-model="prospect.firstname"
          name="firstname"
          type="text"
          required
          maxlength="127"
        />
      </div>
      <div class="form-group col-md-6" :class="{'col-lg-4': !modal}">
        <label for="prospectLastname">{{ $t('Cognome Contatto') }} *</label>
        <x-input
          id="prospectLastname"
          v-model="prospect.lastname"
          name="lastname"
          type="text"
          required
          maxlength="127"
        />
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-12" :class="{'col-lg-8': !modal}">
        <label for="prospectEmail">{{ $t('Email') }} *</label>
        <x-input
          id="prospectEmail"
          v-model="prospect.email"
          name="email"
          type="email"
          required
          maxlength="127"
        />
      </div>
    </div>
  </x-form>
</template>

<script lang="ts">
import XForm from '../components/x-form.vue';
import XInput from '../components/x-input.vue';
import BillingForm from '../components/billing-form.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'FormProspect',

  components: {
    XForm,
    XInput,
    BillingForm,
  },

  props: {
    cleanFields: {
      type: Boolean,
      required: false,
      default: true,
    },
    idProspect: {
      type: Number,
      required: false,
    },
    modal: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['submit', 'update:idProspect'],

  data() {
    return {
      prospect: {
        firstname: '',
        lastname: '',
        email: '',
      },
    };
  },

  methods: {
    onSubmit(e: Event, response: any) {
      this.$emit('submit', e, response);
      this.$emit('update:idProspect', response.id);
    },
  },
});
</script>
