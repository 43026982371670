<template>
  <span v-if="product.nome" v-bind="ouiaProps">
    <template v-if="variant === 'list'">{{ label }}: </template>
    <s v-if="prezzo != prezzo_scontato">
      {{ $currency(prezzo, wizard.customer?.currency) }}
    </s>
    {{ ' ' }}
    <component :is="variant === 'list' ? 'strong' : 'span'">
      {{ $currency(prezzo_scontato, wizard.customer?.currency) }}
      <component v-if="variant !== 'mav'" :is="variant === 'list' ? 'span' : 'div'">
        <template v-if="wizard.customer === undefined || wizard.customer.vat > 0"> + {{ $t('IVA') }} </template> / {{ $t(product.durata) }}
      </component>
    </component>
  </span>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { $t as _t } from '@/i18n';
import { useWizard } from './composition';
import type { ProductPriceInfo } from './wizard.vue';
import { useOUIAProps, type OUIAProps } from '@common/ouia';

defineOptions({
  name: 'WizardPriceTag',
});

interface Props extends OUIAProps {
  product: ProductPriceInfo
  productInstead?: ProductPriceInfo
  variant?: 'table' | 'list' | 'mav',
  label?: string,
  qt?: number,
};

const props = withDefaults(defineProps<Props>(), {
  variant: 'list',
  label: _t('Prezzo'),
  qt: 1,
});

const ouiaProps = useOUIAProps(props);
const wizard = useWizard();

const prezzo = computed(() => {
  if (!props.productInstead) {
    return props.product.prezzo * props.qt;
  }

  return (props.product.prezzo - props.productInstead.prezzo) * props.qt;
});

const prezzo_scontato = computed(() => {
  if (!props.productInstead) {
    return props.product.prezzo_scontato * props.qt;
  }

  return (props.product.prezzo_scontato - props.productInstead.prezzo_scontato) * props.qt;
});
</script>
