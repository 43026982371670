<template>
  <page
    :name="$t('Scadenza')"
    :title="`${payment.fattura ? `${payment.tipo} nr. ${payment.fattura}: ` : ''}${payment.descrizione}`"
    :state="asyncComputed"
  >
    <template #breadcrumbs>
      <router-link v-slot="{ navigate }" custom :to="{ name: 'invoices' }">
        <li>
          <a @click="navigate">{{ $t('Fatture') }}</a>
        </li>
      </router-link>
    </template>

    <p>{{ $t('Scadenza al {0}', [$date(payment.data)]) }}</p>

    <payment-report
      :result="Number($route.query.res)"
      :error="$pop($route.query.err)"
      :success="$t('Saldo scadenza confermato')"
      route="invoices"
      :link="$t('Torna alla lista delle fatture')"
    >
      <form v-if="payment.amount > 0" method="post" action="/api/pay_invoice" @submit="submit">
        <fieldset :disabled="!payment.id">
          <div class="form-group">
            <input type="hidden" name="payment_id" :value="payment.id">
            <payment-methods
              v-model="method"
              :totale-offerta-ivato="payment.amount"
              :currency="login.currency"
              invoice
            />
          </div>

          <h3 class="subtitle">
            {{ $t('Totale') }}: {{ $currency(payment.amount, login.currency) }} <small>{{ $t('IVA inclusa') }}</small>
          </h3>

          <pf-button type="submit" variant="primary" :disabled="!method">
            {{ $t('Procedi al pagamento') }}
          </pf-button>{{ ' ' }}<em>{{ $t("Verrai reindirizzato alla pagina di pagamento. L'operazione può durare qualche secondo.") }}</em>
        </fieldset>
      </form>
    </payment-report>
  </page>
</template>

<style scoped>
.form-group.methods label {
  margin-right: 15px;
}
</style>

<script lang="ts">
import Page from '../components/page.vue';
import PaymentReport from '../components/payment-report.vue';
import PaymentMethods, { type Methods} from '../components/payment-methods.vue';
import { setupAsyncComputed } from '@common/asyncComputed';
import { useLoginStore } from '@/store/login';
import { usePaypalStore } from '@/store/paypal';
import { PaymentsResource, type Payment } from '@/resources';
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'PaymentPage',

  components: {
    Page,
    PaymentReport,
    PaymentMethods,
  },

  setup() {
    const login = useLoginStore();
    return {
      login,
      ...setupAsyncComputed({
        payment: {
          get(this: any) {
            return new PaymentsResource().get(this.$route.params.id);
          },
          default: {} as Payment,
        },
      }),
    };
  },

  data() {
    return {
      method: undefined as Methods | undefined,
    };
  },

  methods: {
    submit(e: Event) {
      if (!this.method) {
        e.preventDefault();
      }
      if (this.method === 'axerve-paypal') {
        const paypal = usePaypalStore();
        paypal.save();
      }
    },
  },
});
</script>
