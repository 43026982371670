<template>
  <page :name="$t('Listino prezzi')" :empty="products.length == 0" :state="[productsState, ccpState]">
    <pf-toolbar ref="toolbar" view="list" views="" style="position:sticky; top: 60px; z-index:1">
      <template #filter>
        <x-input v-model="q" type="search" :placeholder="$t('Filtra')" />
      </template>

      <currency-toggle v-if="!login.currency_locked" id="currency-dropdown" :prefix="$t('Valuta')" />

      <pf-dropdown id="invoicing-dropdown" menu-right :text="$t('Fatturazione') + ': ' + $t(selectDurata)">
        <li>
          <a role="menuitem" @click.prevent="selectDurata = 'Annuale'">
            {{ $t('Annuale') }}
          </a>
        </li>
        <li>
          <a role="menuitem" @click.prevent="selectDurata = 'Mensile'">
            {{ $t('Mensile') }}
          </a>
        </li>
      </pf-dropdown>
      <pf-button v-if="login.is_reseller" @click="showCcpForm = true">
        <edit-icon />  {{ $t('Modifica ricarico') }}
      </pf-button>
    </pf-toolbar>

    <section v-for="category of sortedCategories" :key="category" style="margin-top: 1em">
      <h2 v-if="category">
        {{ category.replace(/(^| — )\d+\.\s*/g, '$1') }}
        <pf-button v-if="login.is_reseller && ricaricoByCategoria(category) != undefined" @click="showCcpForm = true">
          <edit-icon /> {{ $t('Ricarico personalizzato: +{0}%', [ricaricoByCategoria(category)]) }}
        </pf-button>
      </h2>

      <product-list
        :items="catalog[category]"
        :currency="login.currency"
        :hide-plus-vat="login.iva < 0.01"
      />
    </section>
    <pf-modal
      :show="showCcpForm"
      :title="$t('Modifica il ricarico per i tuoi clienti')"
      confirm-button=""
      cancel-button=""
      @cancel="showCcpForm = false"
    >
      <category-custom-prices-form :category-custom-prices="ccp" @submit="ccpSubmit" @close="showCcpForm = false"/>
    </pf-modal>
  </page>
</template>

<script lang="ts" setup>
import EditIcon from '@vue-patternfly/icons/edit-alt-icon';
import { asyncComputedList } from '@common/asyncComputed';
import { any } from '@common/utils';
import XInput from '@/components/x-input.vue';
import ProductList from '../components/product-list.vue';
import Page from '../components/page.vue';
import CurrencyToggle from '../components/currency-toggle.vue';
import CategoryCustomPricesForm from '../components/category-custom-prices-form.vue';
import { useLoginStore } from '@/store/login';
import { CategoryCustomPricesResource, CompanyResource, type CatalogProduct } from '@/resources';
import { computed, ref } from 'vue';

export type NormalizedCatalogProduct = CatalogProduct & {
  prezzo: number;
  prezzo_scontato: number;
  prezzo_ivato: number;
  prezzo_customer?: number | null;
};

defineOptions({
  name: 'CatalogPage',
});

const login = useLoginStore();

const { state: productsState, result: products } = asyncComputedList(async () => {
  if (login.id_azienda) {
    return new CompanyResource().priceList(login.id_azienda).get();
  }
  return [];
});

const q = ref('');
const selectDurata = ref('Annuale');
const showCcpForm = ref(false);

const { state: ccpState, result: ccp } = asyncComputedList(() => new CategoryCustomPricesResource().get());

const sortedCategories = computed(() => {
  const categories = Object.keys(catalog.value);
  categories.sort();
  return categories;
});

const catalog = computed(() => {
  const categorized: Record<string, NormalizedCatalogProduct[]> = {};

  const filter = any(q.value);
  for (const item of products.value) {
    if (filter(item) && filtroDurata(item)) {
      const category = item.categoria ? `${item.posizione_categoria}. ${item.categoria}` : '';

      if (!Object.prototype.hasOwnProperty.call(categorized, category)) {
        categorized[category] = [];
      }

      categorized[category].push({
        ...item,
        prezzo: login.currency == 'EUR' ? item.prezzo_eur : item.prezzo_chf,
        prezzo_scontato: login.currency == 'EUR' ? item.prezzo_scontato_eur : item.prezzo_scontato_chf,
        prezzo_ivato: login.currency == 'EUR' ? item.prezzo_ivato_eur : item.prezzo_ivato_chf,
        prezzo_customer: login.currency == 'EUR' ? item.prezzo_clienti_eur : item.prezzo_clienti_chf,
      });
    }
  }
  return categorized;
});

function filtroDurata(prodotto: CatalogProduct) {
  return prodotto.durata == 'Una tantum' || prodotto.durata == selectDurata.value;
};

function ricaricoByCategoria(category: string) {
  if (login.is_reseller) {
    return category ? ccp.value.filter(v => v.category == category.replace(/(^| — )\d+\.\s*/g, '$1'))[0]?.markup : 0;
  }
  return undefined;
}

function ccpSubmit() {
  showCcpForm.value = false;
  ccpState.update();
  productsState.update();
}
</script>
