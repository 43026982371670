<template>
  <div class="panel panel-default">
    <div class="panel-heading">
      <h3 class="panel-title">
        {{ $t('Inviaci il tuo progetto di comarketing') }}
      </h3>
      <div class="panel-subtitle">
        {{ $t("Scrivici l'idea e parteciperemo al tuo progetto") }}
      </div>
    </div>
    <div>
      <div v-if="sending" class="loading-state-pf">
        <div class="spinner spinner-lg blank-slate-pf-icon" />
        <h3 class="blank-slate-pf-main-action">
          {{ $t('Attendere. Invio in corso...') }}
        </h3>
      </div>

      <form v-else role="form" class="form-collect" @submit.prevent="send">
        <x-input
          v-model="importo"
          name="importo"
          type="number"
          :min="300"
          :max="total"
          step="0.01"
          :disabled="!enabled"
          :placeholder="$t('Importo')"
          required
        />

        <div class="form-group">
          <label>{{ $t('Descrizione') }}</label>
          <textarea
            v-model="description"
            class="form-control"
            :disabled="!enabled"
            :placeholder="$t('Descrivi brevemente il tuo progetto')"
            rows="2"
          />
        </div>

        <div>{{ $t("Inviaci le fatture e le contabili di pagamento associate al progetto") }}</div>
        <div class="form-group attachment-group">
          <label class="btn btn-default" :class="{'disabled': !enabled}">
            <paperclip-icon />
            {{ $t('Allegati') }}
            <input
              type="file"
              ref="inputAttachments"
              name="attachments[]"
              multiple
              :disabled="!enabled"
              style="opacity: 0; position: absolute"
              @change="refreshAttachments"
            >
          </label>

          <template v-if="attachments.length">
            <span v-for="(file, index) in attachments" :key="index" class="label label-default">
              <paperclip-icon />
              {{ file.name }} ({{ $size(file.size) }})
              <a href="#" class="pf-remove-button" @click.prevent="attachments.splice(index, 1)">
                <span class="pficon pficon-close" aria-hidden="true" />
                <span class="sr-only">{{ $t('Rimuovi') }}</span>
              </a>
            </span>
          </template>
        </div>
        <pf-button type="submit" variant="primary" :disabled="!attachments.length">
          <paper-plane-icon /> {{ $t('Invia la richiesta') }}
        </pf-button>
      </form>
    </div>
  </div>
</template>

<style>
.loading-state-pf {
  margin-top: 15px;
}

.attachment-group {
  display: flex;
  flex-direction: row;
  align-items: center;

  > * {
    margin-right: 5px;
  }
}

.form-collect {
  max-width: 592px;
  margin: 15px;
}

.dati-fatturazione > * {
  margin-left: 15px;
}
</style>

<script lang="ts" setup>
import { computed, ref, type Ref } from 'vue';
import { useAppStore } from '@/store/app';
import { $t as _t } from '@/i18n';
import { CreditDocumentsResource } from '@/resources';
import PaperclipIcon from '@vue-patternfly/icons/paperclip-icon';
import PaperPlaneIcon from '@vue-patternfly/icons/paper-plane-icon';
import XInput from './x-input.vue';

const props = defineProps<{
  total: number;
  currency: 'CHF' | 'EUR';
}>();

const emit = defineEmits<{
  (e: 'submit', event: Event): void
}>();

const app = useAppStore();

const importo = ref(props.total);
const sending = ref(false);
const description = ref('');
const attachments: Ref<File[]> = ref([]);
const inputAttachments: Ref<HTMLInputElement | undefined> = ref();

const enabled = computed(() => props.total > 0);

async function send(e: Event) {
  if (!inputAttachments?.value?.files) {
    return;
  }

  sending.value = true;

  try {
    await new CreditDocumentsResource().save({
      tipo: 'comarketing',
      messaggio: description.value,
      allegati: inputAttachments?.value?.files,
      importo: importo.value,
    });

    description.value = '';
    attachments.value = [];
  } finally {
    sending.value = false;
  }

  app.toast(_t('Richiesta inviata correttamente'), 'success');
  emit('submit', e);
};

function refreshAttachments() {
  if (!inputAttachments?.value?.files) {
    return;
  }

  for (const file of inputAttachments.value.files) {
    attachments.value.push(file);
  }
};

</script>
