<template>
  <form role="form">
    <div v-for="ccp of categories" class="form-group row">
      <label for="companyRagioneSociale" class="col-xs-12 col-md-6">{{ ccp.category }}</label>
      <div class="col-xs-3 col-md-2">
        <x-input
          v-model="ccp.markup"
          :name="ccp.category"
          type="number"
          min="0"
          step="1"
          required
          append="%"
        />
      </div>
    </div>
    <div class="form-group">
      <pf-button variant="primary" @click="save">
        {{ $t('Salva') }}
      </pf-button>
      {{ ' ' }}
      <pf-button  @click="emit('close')">
        {{ $t('Annulla') }}
      </pf-button>
    </div>
  </form>
</template>

<style scoped>
.form-group input, .form-group button {
  margin-left: 20px;
}
.form-group input {
  padding: 0;
}
</style>

<script lang="ts" setup>
import { ref } from 'vue';
import { type CategoryCustomPrice, CategoryCustomPricesResource } from '@/resources';
import { cloneDeep } from 'lodash-es';
import XInput from './x-input.vue';

const props = defineProps<{
  categoryCustomPrices: CategoryCustomPrice[];
}>();

const categories = ref(cloneDeep(props.categoryCustomPrices));

const emit = defineEmits<{
  (e: 'submit'): void;
  (e: 'close'): void;
}>();

async function save() {
  categories.value = categories.value.map(item => ({
      category: item.category,
      markup: Number(item.markup),
  }));
  await new CategoryCustomPricesResource().save(categories.value);
  emit('submit');
}
</script>
