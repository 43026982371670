<template>
  <div class="row mav-item">
    <div class="col-xs-4 col-sm-2"><cpu-icon /> {{ $t('CPU') }}:</div>
    <div class="col-xs-8 col-sm-2">
      <x-input
        type="number"
        name="cpuQtn"
        required
        :min="1"
        :max="64"
        append="Core"
        :model-value="cpuQtn"
        @update:model-value="emit('update:cpuQtn', Number($event))"
      />
    </div>
  </div>
  <div class="row mav-item">
    <div class="col-xs-4 col-sm-2"><memory-icon /> {{ $t('RAM') }}:</div>
    <div class="col-xs-8 col-sm-2">
      <x-input
        type="number"
        name="ramQtn"
        required
        :min="1"
        :max="256"
        append="GB"
        :model-value="ramQtn"
        @update:model-value="emit('update:ramQtn', Number($event))"
      />
    </div>
  </div>
  <div class="row mav-item">
    <div class="col-xs-4 col-sm-2"><volume-icon /> {{ $t('Storage') }}:</div>
    <div class="col-xs-8 col-sm-2">
      <x-input
        type="number"
        name="storageQtn"
        required
        :min="10"
        :max="5000"
        append="GB"
        :model-value="storageQtn"
        @update:model-value="emit('update:storageQtn', Number($event))"
      />
    </div>
  </div>
  <wizard-price-tag :product="wizard.serverMav" />

  <div class="question-wrapper">
    <h3>{{ $t('Nome del server') }}</h3>
    <x-input
      :model-value="nomeServer"
      name="nome_server"
      type="hostname"
      required
      :placeholder="_t('Nome del server')"
      @update:model-value="emit('update:nomeServer', String($event))"
      class="nome_server"
    >
      <template #append>
        .artera.farm
      </template>
    </x-input>
  </div>

  <div class="question-wrapper">
    <h3>{{ $t('Vuoi attivare anche un firewall?') }}</h3>
    <wizard-question-prodotto
      v-model="wizard.addOnFirewall"
      :product="wizard.firewallMav"
      show-prezzo
    />
  </div>
</template>

<style lang="scss" scoped>
.mav-item{
  margin-bottom: 10px;

  input {
    width: 50px;
  }
}
.nome_server{
  max-width: 300px;
}
</style>

<script setup lang="ts">
import { $t as _t } from '@/i18n';
import XInput from '@/components/x-input.vue';
import wizardQuestionProdotto from './wizard-question-prodotto.vue';
import WizardPriceTag from '../wizard-price-tag.vue';
import { useWizard } from '../composition';
import CpuIcon from '@vue-patternfly/icons/cpu-icon';
import MemoryIcon from '@vue-patternfly/icons/memory-icon';
import VolumeIcon from '@vue-patternfly/icons/volume-icon';

defineProps<{
  cpuQtn: number;
  ramQtn: number;
  storageQtn: number;
  nomeServer: string;
}>();

const emit = defineEmits<{
  (name: `update:${'cpu'|'ram'|'storage'}Qtn`, v: number): void;
  (name: `update:nomeServer`, v: string): void;
}>();

const wizard = useWizard();
</script>
