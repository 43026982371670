<template>
  <form role="form" @submit.prevent="save">
    <template v-if="loading">
      <div class="blank-slate-pf">
        <div class="spinner spinner-lg blank-slate-pf-icon" />

        <slot name="loading">
          <h3 class="blank-slate-pf-main-action">
            {{ _t('Caricamento configurazione zona in corso...') }}
          </h3>
        </slot>
      </div>
    </template>

    <table v-else class="table dataTable table-striped">
      <thead>
        <tr>
          <th>{{ _t('Dominio') }}</th>
          <th class="column-small">TTL</th>
          <th class="column-small">
            {{ _t('Tipo') }}
          </th>
          <th class="column-small">
            {{ _t('Peso') }}
          </th>
          <th>Target</th>
          <th v-if="login.grants.developer" />
        </tr>
      </thead>
      <tbody>
        <tr v-for="(record, i) in records" :key="i" role="row">
          <td>
            <x-input
              v-model="record.domain"
              :placeholder="_t('Dominio')"
              :disabled="!login.grants.developer"
              :pattern="domainValidPattern(record)"
              :title="record.type === 'CNAME' ? _t('Non è possibile mischiare record CNAME con altri tipi per lo stesso dominio') : undefined"
            />
          </td>
          <td>
            <x-input
              v-model="record.ttl"
              type="number"
              placeholder="TTL"
              min="800"
              max="2147483647"
              :disabled="!login.grants.developer"
            />
          </td>
          <td>
            <select v-model="record.type" class="form-control" :disabled="!login.grants.developer">
              <option value="A">A</option>
              <option value="CAA">CAA</option>
              <option value="CNAME">CNAME</option>
              <option value="DNAME">DNAME</option>
              <option value="DS">DS</option>
              <option value="LOC">LOC</option>
              <option value="MX">MX</option>
              <option value="TXT">TXT</option>
              <option value="RP">RP</option>
              <option value="SPF">SPF</option>
              <option value="SRV">SRV</option>
              <option value="SSHPF">SSHPF</option>
            </select>
          </td>
          <td>
            <x-input
              v-if="record.type == 'MX'"
              v-model="record.weight"
              type="number"
              :placeholder="_t('Peso')"
              min="0"
              max="2147483647"
            />
          </td>
          <td>
            <x-input v-model="record.target" :type="targetType(record.domain, record.type)" placeholder="Target" :disabled="!login.grants.developer">
              <div
                v-if="dominio && ['CNAME', 'DNAME', 'MX'].includes(record.type ?? '') && !isFQ(record.target)"
                class="input-group-addon"
              >
                .{{ dominio }}
              </div>
            </x-input>
          </td>
          <td v-if="login.grants.developer" class="table-view-pf-actions">
            <div class="table-view-pf-btn">
              <pf-button variant="danger" v-bind="{title: 'Elimina'}" @click="remove(i)">
                <trash-icon />
              </pf-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="login.grants.developer" class="form-footer">
      <div class="pull-right">
        <pf-button :disabled="!modified" @click="undo">
          {{ _t('Annulla') }}
        </pf-button>
        &nbsp;
        <pf-button type="submit" variant="primary" :disabled="!modified">
          <save-icon /> {{ _t('Salva') }}
        </pf-button>
      </div>

      <pf-button variant="success" :disabled="loading" @click="add">
        <circle-plus-icon /> {{ _t('Nuovo Record') }}
      </pf-button>
      &nbsp;
      <pf-dropdown ref="defaultRecordsDropdown" :text="_t('Record di posta predefiniti')">
        <li v-if="dominio" @click="addOffice365Records">
          <a>{{ _t('Aggiungi record di Office 365') }}</a>
        </li>
        <li @click="addGoogleMxRecords">
          <a>{{ _t('Aggiungi record di GMail') }}</a>
        </li>
      </pf-dropdown>
    </div>
    <pf-notification v-else type="warning">
      {{ _t('Modifica dei record DNS non abilitata. Richiedi i permessi tecnici ad un utente amministratore per visualizzarle') }}
    </pf-notification>

    <div class="pull-right download-csv">
      <pf-button variant="warning" :disabled="loading" @click="scarica">
        <download-icon /> {{ _t('Scarica la zona DNS') }}
      </pf-button>
    </div>
  </form>
</template>

<style lang="scss" scoped>
table.dataTable {
  margin-bottom: 20px;
}
.column-small {
  width: 8em;
}

.form-footer {
  padding-top: 10px;
}

.download-csv{
  margin-top: 20px;
}
</style>

<script setup lang="ts">
import isEqual from 'lodash-es/isEqual';
import cloneDeep from 'lodash-es/cloneDeep';
import { saveAs } from '@common/utils';
import XInput from './x-input.vue';
import { DnsResource, type DnsRecord } from '@/resources';
import { useLoginStore } from '@/store/login';
import { computed, watch, type Ref, ref } from 'vue';
import type { PfDropdown } from 'vue-patternfly';
import { isDefined } from '@vueuse/shared';
import { $t as _t } from '@/i18n';
import escapeStringRegexp from 'escape-string-regexp';
import DownloadIcon from '@vue-patternfly/icons/download-icon';
import CirclePlusIcon from '@vue-patternfly/icons/circle-plus-icon';
import SaveIcon from '@vue-patternfly/icons/save-alt-icon';
import TrashIcon from '@vue-patternfly/icons/trash-icon';

defineOptions({
  name: 'DnsRecords',
});

const props = defineProps<{
  dominio: string;
}>();

const login = useLoginStore();
const defaultRecordsDropdown: Ref<typeof PfDropdown | undefined> = ref();

const loading = ref(false);
const records: Ref<Partial<DnsRecord>[]> = ref([]);
const undoRecords: Ref<Partial<DnsRecord>[]> = ref([]);

const modified = computed(() => !isEqual(records.value, undoRecords.value));

watch(() => props.dominio, async () => {
  loading.value = true;
  try {
    records.value = await new DnsResource().get(props.dominio);
    undoRecords.value = cloneDeep(records.value);
  } finally {
    loading.value = false;
  }
}, { immediate: true });

function add() {
  records.value.push({
    type: 'A',
  });
}

function remove(record: number) {
  records.value.splice(record, 1);
}

function undo() {
  records.value = cloneDeep(undoRecords.value);
}

async function save() {
  await new DnsResource().save(props.dominio, records.value);
  undoRecords.value = cloneDeep(records.value);
}

function isDKIM(domain: string | null | undefined) {
  return domain?.substring(domain.length - 11) === '._domainkey';
}

function isDMARC(domain: string | null | undefined) {
  return domain === '_dmarc' || domain?.startsWith('_dmarc.');
}

function isFQ(target: string | null | undefined) {
  return target?.substring(target.length - 1) === '.';
}

function targetType(domain: string | null | undefined, type: string | null | undefined) {
  if (type == 'TXT' && isDKIM(domain)) {
    return 'dkim';
  }
  if (type == 'TXT' && isDMARC(domain)) {
    return 'dmarc';
  }
  if (type == 'A') {
    return 'ipv4';
  }
  return 'text';
}

function domainValidPattern(record: Partial<DnsRecord>) {
  if (record.type !== 'CNAME') {
    return undefined;
  }
  return `(?!${records.value.filter(r => r.domain && r !== record).map(r => r.domain).filter((d, index, array) => array.indexOf(d) === index).map(d => `${escapeStringRegexp(d ?? '')}$`).join('|')}).*`;
}

function removeAllMxRecord() {
  for (let i = records.value.length - 1; i >= 0; --i) {
    if (records.value[i].type == 'MX') {
      remove(i);
    }
  }
}

function findSpfRecord() {
  for (const record of records.value) {
    if (['SPF', 'TXT'].includes(record.type ?? '') && record.target?.startsWith('v=spf1 ')) {
      return record;
    }
  }
}

function addGoogleMxRecords() {
  defaultRecordsDropdown.value?.toggle(false);

  if (!window.confirm(_t('Attenzione! Eventuali MX già presenti verranno rimossi e sostituiti da quelli scelti. Vuoi procedere?'))) {
    return;
  }

  removeAllMxRecord();

  const spf = findSpfRecord();
  if (spf?.target) {
    if (!spf.target.includes(' include:_spf.google.com ')) {
      spf.target = spf.target.replace('v=spf1 ', 'v=spf1 include:_spf.google.com ');
    }
  } else {
    records.value.push({
      domain: '@',
      type: 'TXT',
      target: 'v=spf1 a mx include:spf.artera.net include:_spf.google.com -all',
    });
  }

  records.value.push({
    domain: '@',
    type: 'MX',
    weight: '1',
    target: 'ASPMX.L.GOOGLE.COM.',
  });
  records.value.push({
    domain: '@',
    type: 'MX',
    weight: '5',
    target: 'ALT1.ASPMX.L.GOOGLE.COM.',
  });
  records.value.push({
    domain: '@',
    type: 'MX',
    weight: '5',
    target: 'ALT2.ASPMX.L.GOOGLE.COM.',
  });
  records.value.push({
    domain: '@',
    type: 'MX',
    weight: '10',
    target: 'ALT3.ASPMX.L.GOOGLE.COM.',
  });
  records.value.push({
    domain: '@',
    type: 'MX',
    weight: '10',
    target: 'ALT4.ASPMX.L.GOOGLE.COM.',
  });
}

function addOffice365Records() {
  defaultRecordsDropdown.value?.toggle(false);

  if (!props.dominio) {
    return;
  }

  if (!window.confirm(_t('Attenzione! Eventuali MX già presenti verranno rimossi e sostituiti da quelli scelti. Vuoi procedere?'))) {
    return;
  }

  removeAllMxRecord();

  const spf = findSpfRecord();
  if (spf?.target) {
    if (!spf.target.includes(' include:spf.protection.outlook.com ')) {
      spf.target = spf.target.replace('v=spf1 ', 'v=spf1 include:spf.protection.outlook.com ');
    }
  } else {
    records.value.push({
      domain: '@',
      type: 'TXT',
      target: 'v=spf1 a mx include:spf.artera.net include:spf.protection.outlook.com -all',
    });
  }

  records.value.push({
    domain: '@',
    type: 'MX',
    weight: '0',
    target: `${props.dominio.replace(/\./g, '-')}.mail.protection.outlook.com.`,
  });
  records.value.push({
    domain: 'autodiscover',
    type: 'CNAME',
    ttl: '3600',
    target: 'autodiscover.outlook.com.',
  });
  records.value.push({
    domain: 'sip',
    type: 'CNAME',
    ttl: '3600',
    target: 'sipdir.online.lync.com.',
  });
  records.value.push({
    domain: 'lyncdiscover',
    type: 'CNAME',
    ttl: '3600',
    target: 'webdir.online.lync.com.',
  });
  records.value.push({
    domain: 'msoid',
    type: 'CNAME',
    ttl: '3600',
    target: 'clientconfig.microsoftonline-p.net.',
  });
  records.value.push({
    domain: 'enterpriseregistration',
    type: 'CNAME',
    ttl: '3600',
    target: 'enterpriseregistration.windows.net.',
  });
  records.value.push({
    domain: 'enterpriseenrollment',
    type: 'CNAME',
    ttl: '3600',
    target: 'enterpriseenrollment.manage.microsoft.com.',
  });
  records.value.push({
    domain: '_sip._tls',
    type: 'SRV',
    ttl: '3600',
    target: '100 1 443 sipdir.online.lync.com.',
  });
  records.value.push({
    domain: '_sipfederationtls._tcp',
    type: 'SRV',
    ttl: '3600',
    target: '100 1 5061 sipfed.online.lync.com.',
  });
}

function scarica() {
  let csv = ['DOMINIO', 'TTL', 'TIPO', 'PESO', 'TARGET'];

  csv = [csv.join(';')];

  for (const row of records.value) {
    const csvrow = [];
    for (const f of ['domain', 'ttl', 'type', 'weight', 'target'] as const) {
      let value = row[f];

      if (!isDefined(value)) {
        csvrow.push('');
        continue;
      }

      // Target check
      if (f == 'target' && !isFQ(value) && (row.type == 'CNAME' || row.type == 'MX')) {
        value = `${value}.${props.dominio}.`;
      }

      const typ = typeof value;
      if (typ == 'string') {
        value = `"${value.split('"').join('""')}"`;
      } else if (typ == 'number') {
        value = parseFloat(value).toLocaleString();
      }

      csvrow.push(value);
    }
    csv.push(csvrow.join(';'));
  }

  const blob = new Blob([csv.join('\r\n')], {
    type: 'text/csv;charset=utf-8',
  });
  saveAs(blob, `${props.dominio.replace('.', '')}-dns.csv`);
}
</script>
