<template>
  <login-layout>
    <header class="login-pf-header">
      <h1>{{ $t('Recupero Password') }}</h1>
    </header>

    <form ref="form" @submit.prevent="submit">
      <div class="form-group">
        <label class="sr-only" for="resetUsername">{{ $t('Nome utente o Email') }}</label>
        <x-input
          id="resetUsername"
          type="text"
          name="username"
          :placeholder="$t('Nome utente o Email')"
          required
          :disabled="sending"
          autocomplete="username"
        />
      </div>

      <pf-button type="submit" variant="primary" large block :disabled="sending">
        {{ $t('Procedi') }}
        <div v-if="sending" class="spinner spinner-xs" />
      </pf-button>
    </form>

    <p class="login-pf-signup">
      <i18n-t keypath="Torna alla schermata di {0}">
        <router-link :to="{ name: 'login' }">
          {{ $t('Log In') }}
        </router-link>
      </i18n-t>
    </p>
  </login-layout>
</template>

<script lang="ts">
import { http } from '../../http';
import LoginLayout from '../../components/login-layout.vue';
import XInput from '@/components/x-input.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ResetpasswordPage',

  components: {
    LoginLayout,
    XInput,
  },

  data() {
    return {
      sending: false,
    };
  },

  methods: {
    async submit() {
      this.sending = true;

      try {
        const data = new FormData(this.$refs.form as HTMLFormElement);
        data.append('check', (await http.post('/api/auth/token')).data.token);
        const result = (await http.post('/api/auth/reset', data)).data.result;
        if (result == 'ok') {
          this.$router.push({
            name: 'login',
            query: this.$route.query,
          });
        }
      } finally {
        this.sending = false;
      }
    },
  },
});
</script>
