<template>
  <form role="form" @submit.prevent="changePassword">
    <fieldset :disabled="!login.id">
      <div class="row">
        <div class="form-group col-md-6 col-lg-4">
          <label for="anagraficaResetPassword">{{ _t('Password') }}</label>
          <x-input
            id="anagraficaResetPassword"
            v-model="password"
            type="password"
            minlength="8"
            required
            autocomplete="new-password"
          />
        </div>

        <div class="form-group col-md-6 col-lg-4">
          <label for="anagraficaConfirmPassword">{{ _t('Conferma Password') }}</label>
          <x-input
            id="anagraficaConfirmPassword"
            v-model="confirmPassword"
            type="password"
            minlength="8"
            required
            autocomplete="off"
          />
        </div>
      </div>

      <pf-button type="submit" variant="primary" :disabled="!password || password != confirmPassword || sending">
        {{ _t('Cambia Password') }}
      </pf-button>
    </fieldset>
  </form>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { $t as _t } from '@/i18n';
import { useAppStore } from '@/store/app';
import { useLoginStore } from '@/store/login';
import { Resource } from '@/resources';
import XInput from './x-input.vue';

const password = ref('');
const confirmPassword = ref('');
const sending = ref(false);
const login = useLoginStore();

async function changePassword() {
  const app = useAppStore();

  sending.value = true;
  try {
    if (!login.id) {
      return;
    }
    await new Resource('login').put(login.id, {
      password: password.value,
    });
    app.toast(_t('Password modificata correttamente.'), 'success');
  } catch {
    app.toast(_t('Errore: password non modificata. Contatta il nostro supporto per maggiori informazioni.'), 'danger');
  } finally {
    sending.value = false;
  }
  password.value = '';
  confirmPassword.value = '';
};
</script>
