<template>
  <template v-if="withDevelopersGrants">
    <div class="row">
      <div class="col-md-5">
        <h3>Dati di accesso</h3>
        <div class="row">
          <div class="form-group col-md-8">
            <label :for="`service-username-${index}`" class="control-label">{{ $t('Username') }}</label>
            <x-input
              :id="`service-username-${index}`"
              type="text"
              readonly
              v-model="pannello.username"
            />
          </div>
          <div class="form-group col-md-8">
            <label :for="`service-password-${index}`" class="control-label">{{ $t('Resetta la password') }}</label>
            <form-input :id="`service-password-${index}`" v-model="password" name="password" @save="save" />
          </div>
        </div>
      </div>
      <div class="col-md-5 control-panel">
        <h3>{{ $t('Interfaccia di gestione') }}</h3>
        <pf-button @click="loginCpanel(pannello.id)">
          {{ $t('Pannello di Controllo') }} (cPanel) <arrow-up-right-from-square-icon />
        </pf-button>
        <a
          v-if="!isPosta"
          :href="`https://${pannello.server_fqdn}:2083/3rdparty/phpMyAdmin/`"
          class="btn btn-default"
          target="_blank"
          rel="noreferer"
        >
          {{ $t('Gestione database') }} (phpMyAdmin) <arrow-up-right-from-square-icon />
        </a>
        <pf-button v-if="withDevelopersGrants && !isPosta" @click="openSshModal = true">
          {{ $t('Gestisci chiavi SSH') }} <lock-icon />
        </pf-button>
      </div>
    </div>
  </template>

  <template v-if="(isDefined(pannello.quotaused) && isDefined(pannello.quotalimit)) || (isDefined(pannello.bwused) && isDefined(pannello.bwlimit))">
    <h3>{{ $t('Consumi') }}</h3>
    <pf-utilization-bar-chart
      v-if="(isDefined(pannello.quotaused) && isDefined(pannello.quotalimit))"
      inline
      :title="$t('Spazio')"
      units="MB"
      :warning="75"
      :error="95"
      :value="Number(pannello.quotaused)"
      :total="Number(pannello.quotalimit)"
    >
      <template #footer>
        <i18n-t keypath="{0} usati">
          <strong>{{ $size(pannello.quotaused*1000000) }} / {{ $size(pannello.quotalimit*1000000) }}</strong>
        </i18n-t>
      </template>
    </pf-utilization-bar-chart>

    <pf-utilization-bar-chart
      v-if="!isPosta && isDefined(pannello.bwused) && isDefined(pannello.bwlimit)"
      inline
      :title="$t('Traffico')"
      units="MB"
      :warning="75"
      :error="95"
      :value="Number(pannello.bwused)"
      :total="Number(pannello.bwlimit)"
    >
      <template #footer>
        <i18n-t keypath="{0} usati">
          <strong>{{ $size(pannello.bwused*1000000) }} / {{ pannello.bwlimit === 0 ? '∞' : $size(pannello.bwlimit*1000000) }}</strong>
        </i18n-t>
      </template>
    </pf-utilization-bar-chart>
  </template>

  <div v-if="pannello.traffico && !isPosta">
    {{ $t('Upgrade automatico del traffico allo sforamento del limite previsto dal piano') }}:
    <div class="btn-group btn-group-flip inline-with-text">
      <btn-radio
        v-model="local_auto_upgrade_bw"
        class="btn-sm"
        active-class="btn-success"
        inactive-class="btn-default"
        :checked-value="true"
        :disabled="saving_auto_upgrade_bw"
      >
        {{ $t('Attivo') }}
      </btn-radio>
      <btn-radio
        v-model="local_auto_upgrade_bw"
        class="btn-sm"
        active-class="btn-danger"
        inactive-class="btn-default"
        :checked-value="false"
        :disabled="saving_auto_upgrade_bw"
      >
        {{ $t('Disattivo') }}
      </btn-radio>
    </div>
    {{ ' ' }}
    <pf-spinner v-if="saving_auto_upgrade_bw" size="sm" inline />
    {{ ' ' }}
    <form-help-tooltip
      :text="$t('Attivando l’upgrade automatico del traffico questo hosting potrà superare i limiti mensili di traffico compresi nell\'attuale piano attivo, indicati qui sopa. Il costo di questo servizio è di {0} / Tb / mese che verranno fatturati solamente in caso di superamento. Il primo mese è gratis.', [automaticUpgradeCost])"
    />
  </div>

  <div v-if="pannello.server_location || pannello.backup_location" class="row">
    <div v-if="pannello.server_location" class="col-md-5">
      <h3>{{ $t('Server') }}</h3>
      <div>{{ $t('Location del server') }}: {{ pannello.server_location }}</div>
    </div>
    <div v-if="pannello.backup_location" class="col-md-5">
      <h3>{{ $t('Server di Backup') }}</h3>
      <div>{{ $t('Location dei backup') }}: {{ pannello.backup_location }}</div>
      <div>{{ allFeatures.backup.tooltip }}</div>
      <div>{{ $t('Stato') }}: {{ $t('Attivo') }} <circle-icon class="text-success" /></div>
    </div>
  </div>

  <pf-modal
    :title="$t('Chiavi SSH')"
    outside-close
    confirm-button=""
    cancel-button=""
    :show="openSshModal"
    @close="openSshModal = false"
  >
    <ssh-keys :id-pannello="pannello.id" />
  </pf-modal>
</template>

<style lang="scss">
.control-panel a, .control-panel button {
  display: block;
  margin-top: 16px;
  width: 230px;
  text-align: left;
}
</style>

<script lang="ts" setup>
import { ref, computed, watch } from 'vue';
import { isDefined } from '@vueuse/shared';
import { type ServicePannello } from '@/resources';
import FormInput from '../components/form-input.vue';
import SshKeys from '../components/ssh-keys.vue';
import BtnRadio from '../components/btn-radio.vue';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';
import { Resource, CpanelLoginResource } from '@/resources';
import { allFeatures } from './product-feature.vue';
import ArrowUpRightFromSquareIcon from '@vue-patternfly/icons/arrow-up-right-from-square-icon';
import CircleIcon from '@vue-patternfly/icons/circle-icon';
import LockIcon from '@vue-patternfly/icons/lock-icon';
import XInput from './x-input.vue';

const props = defineProps<{
  dominio: string,
  pannello: ServicePannello;
  currency: 'EUR' | 'CHF';
  index: number;
  isPosta?: boolean;
  withDevelopersGrants?: boolean;
}>();

const openSshModal = ref(false);
const password = ref('');
const local_auto_upgrade_bw = ref(props.pannello.auto_upgrade_bw);
const upstream_auto_upgrade_bw = ref(props.pannello.auto_upgrade_bw);
const saving_auto_upgrade_bw = ref(false);

const automaticUpgradeCost = computed(() => props.currency == 'EUR' ? '20,00 €' : '25.- CHF');


watch(local_auto_upgrade_bw, async() => {
  if (
    typeof local_auto_upgrade_bw.value == 'undefined' ||
    local_auto_upgrade_bw.value == upstream_auto_upgrade_bw.value
  ) {
    return;
  }
  saving_auto_upgrade_bw.value = true;
  try {
    await new Resource('services').save(props.dominio, { auto_upgrade_bw: local_auto_upgrade_bw });
    upstream_auto_upgrade_bw.value = local_auto_upgrade_bw.value;
  } catch (e) {
    local_auto_upgrade_bw.value = upstream_auto_upgrade_bw.value;
  } finally {
    saving_auto_upgrade_bw.value = false;
  }
});

async function loginCpanel(id: number) {
  window.open(await new CpanelLoginResource().get(id));
};

async function save(value: string, name: string | undefined) {
  if (!name) {
    return;
  }

  try {
    await new Resource('services').save(props.dominio, {
      [name]: value,
    });
  } catch (ex: any) {
    if (!ex.response || ![401, 404, 422].includes(ex.response.status)) {
      throw ex;
    }
  }
};
</script>
