<template>
  <h3>
    {{ $t('Ecco il riepilogo delle tue richieste per il dominio {0}', [wizard.nomeDominio]) }}
  </h3>
  <div v-bind="ouiaProps" class="riepilogo-wrapper">
    <div>
      <h4>{{ $t('Servizio richiesto') }}</h4>
      <ul>
        <li v-if="wizard.askClienti && wizard.customer">
          {{ $t('Offerta intestata a') }}:
          <strong class="capitalized">
            {{ wizard.customer.ragione_sociale }}
          </strong>
          {{ ' ' }}
          <pf-button variant="link" class="modifica" @click="wizard.goToStep('WizardStepClienti')">
            <edit-icon />
          </pf-button>
        </li>
        <li>
          <strong v-if="wizard.path">{{ wizard.paths[wizard.path]?.label }}</strong>
          {{ ' ' }}
          <pf-button variant="link" class="modifica" @click="wizard.goToStep('WizardStepScopo')">
            <edit-icon />
          </pf-button>
        </li>
        <li v-if="wizard.askOs && !wizard.askEssentialOs && !wizard.askCockpitConfig">
          {{ $t('Sistema operativo') }}:
          <strong class="capitalized">
            {{ wizard.os }}
          </strong>
        </li>
        <li v-if="wizard.askProdotti">
          {{ $t('Prodotto') }}:
          <template v-if="wizard.path === 'cloud'">
            {{ wizard.cpuMavQt }} <cpu-icon title="cpu" class="mav" /> {{ wizard.ramMavQt }} GB <memory-icon title="ram" class="mav" /> {{ wizard.storageMavQt }} GB <volume-icon title="storage" class="mav" />
          </template>
          <strong v-else>
            {{ wizard.sceltaProdottoInfrastruttura }}
          </strong>
          {{ ' ' }}
          <pf-button variant="link" class="modifica" @click="wizard.goToStep('WizardStepProdottiInfrastruttura')">
            <edit-icon />
          </pf-button>
        </li>
        <li v-if="wizard.askStack">
          {{ $t('Stack') }}:
          <strong>{{ wizard.stack ?? $t('Nessuno') }} <template v-if="wizard.askEssentialOs">({{ wizard.essentialOs }})</template><template v-else-if="wizard.askCockpitConfig">({{ wizard.cockpitOs }} - {{ wizard.cockpitWs }})</template></strong>
          {{ ' ' }}
          <pf-button variant="link" class="modifica" @click="wizard.goToStep('WizardStepStack')">
            <edit-icon />
          </pf-button>
        </li>
        <li v-if="wizard.askHardening">
          {{ $t('Hardening') }}:
          <strong>{{ wizard.hardening.length === 0 ? $t("Nessuno") : $t("Personalizzato") }}</strong>
          <form-help-tooltip v-if="wizard.hardening.length" :text="wizard.hardening.join(', ')" />
          {{ ' ' }}
          <pf-button variant="link" class="modifica" @click="wizard.goToStep('WizardStepHardening')">
            <edit-icon />
          </pf-button>
        </li>
      </ul>
    </div>
    <div v-if="wizard.askLocation">
      <h4>{{ $t('Location') }}</h4>
      <ul>
        <li>
          {{ $t('Deploy Location') }}:
          <strong v-if="wizard.sceltaLocationDeploy">{{ $t(wizard.deployLocations[wizard.sceltaLocationDeploy] ?? '') }}</strong>
          {{ ' ' }}
          <pf-button variant="link" class="modifica" @click="wizard.goToStep('WizardStepLocation')">
            <edit-icon />
          </pf-button>
        </li>
        <li v-if="wizard.askLocationBackup">
          {{ $t('Backup Location') }}:
          <strong v-if="wizard.sceltaLocationBackup">{{ $t(wizard.backupLocations[wizard.sceltaLocationBackup]) }}</strong>
          {{ ' ' }}
          <pf-button variant="link" class="modifica" @click="wizard.goToStep('WizardStepLocation')">
            <edit-icon />
          </pf-button>
        </li>
      </ul>
    </div>

    <div>
      <h4>{{ $t('Dominio') }}</h4>
      <ul>
        <li v-if="wizard.askDomain">
          {{ $t('Nome a Dominio') }}:
          <strong>{{ wizard.nomeDominio }}</strong>
          {{ ' ' }}
          <pf-button variant="link" class="modifica" @click="wizard.goToStep('WizardStepDominio')">
            <edit-icon />
          </pf-button>
        </li>
        <li v-else="wizard.askDomain">
          {{ $t('Nome Server') }}:
          <strong>{{ wizard.nomeServer }}</strong>
          {{ ' ' }}
          <pf-button variant="link" class="modifica" @click="wizard.goToStep('WizardStepProdottiInfrastruttura')">
            <edit-icon />
          </pf-button>
        </li>
        <template v-if="wizard.askRegistrazione && wizard.nomeDominio && wizard.dominioRegistrabile">
          <li>
            {{ $t('Registrazione Dominio') }}:
            <strong>{{ siNo(wizard.domainAction === 'registrazione') }}</strong>
            {{ ' ' }}
            <pf-button variant="link" class="modifica" @click="wizard.goToStep('WizardStepDominio')">
              <edit-icon />
            </pf-button>
          </li>
          <li v-if="wizard.domainAction">
            {{ $t('Attivazione DNS') }}:
            <strong>{{ siNo(['registrazione', 'dns'].includes(wizard.domainAction)) }}</strong>
            {{ ' ' }}
            <pf-button variant="link" class="modifica" @click="wizard.goToStep('WizardStepDominio')">
              <edit-icon />
            </pf-button>
          </li>
          <li v-if="wizard.registraDominio">
            {{ $t('Attivazione Registrazione Premium') }}:
            <strong>{{ siNo(wizard.registrazionePremium) }}</strong>
            {{ ' ' }}
            <pf-button variant="link" class="modifica" @click="wizard.goToStep('WizardStepDominio')">
              <edit-icon />
            </pf-button>
          </li>
        </template>
      </ul>
    </div>

    <div v-if="wizard.askSsl || wizard.askMail">
      <h4>{{ $t('Servizi Aggiuntivi') }}</h4>
      <ul>
        <li v-if="wizard.askSsl">
          {{ $t('Attivazione Certicato SSL') }}:
          <strong>{{ siNo(!!wizard.codiceSsl) }}</strong>
          {{ ' ' }}
          <pf-button variant="link" class="modifica" @click="wizard.goToStep('WizardStepSsl')">
            <edit-icon />
          </pf-button>
        </li>
        <li v-if="wizard.askMail">
          {{ $t('Prodotto Mail') }}:
          <strong>
            {{ siNo(!!wizard.codiceProdottoMail) }}
            <span v-if="wizard.codiceProdottoMail"> ({{ wizard.codiceProdottoMail }}) </span>
          </strong>
          {{ ' ' }}
          <pf-button variant="link" class="modifica" @click="wizard.goToStep('WizardStepMail')">
            <edit-icon />
          </pf-button>
        </li>
      </ul>
    </div>
  </div>
</template>

<style scoped>
.pficon-edit {
  margin-left: 5px;
}
.mav {
  position: relative;
  top: 2px;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import { ouiaProps, useOUIAProps } from '@common/ouia';
import EditIcon from '@vue-patternfly/icons/edit-alt-icon';
import CpuIcon from '@vue-patternfly/icons/cpu-icon';
import MemoryIcon from '@vue-patternfly/icons/memory-icon';
import VolumeIcon from '@vue-patternfly/icons/volume-icon';
import { useWizard } from './composition';
import { $t } from '@/i18n';
import FormHelpTooltip from '@common/FormHelpTooltip.vue';

export default defineComponent({
  name: 'WizardSummaryGoto',

  components: {
    EditIcon,
    FormHelpTooltip,
    CpuIcon,
    MemoryIcon,
    VolumeIcon,
  },

  props: {
    ...ouiaProps,
  },

  setup(props) {
    return {
      ouiaProps: useOUIAProps(props),
      wizard: useWizard(),
    };
  },

  methods: {
    attiva(value: boolean) {
      return value ? $t('Attiva') : $t('Non attiva');
    },

    siNo(value: boolean) {
      return value ? $t('Sì') : $t('No');
    },
  },
});
</script>
